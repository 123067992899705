import React, { useContext } from 'react';

import Header from './Header';
import Hero from './Hero';
import TextBanner from './TextBanner';
import CoachPanel from './CoachPanel';
import ImageTextCard from './ImageTextCard';
import ImageTextCardTwo from './ImageTextCardTwo';
import Footer from './Footer';

import { AuthContext } from '../AuthContext';


function Home() {

  const currentUser = useContext(AuthContext);

  return (

    <>
        {currentUser ? 
        <>
          <Header/>
          <Hero/>
          <TextBanner/>
          <CoachPanel/>
          <ImageTextCard/>
          <ImageTextCardTwo/>
          <Footer/>  
        </>
        :
        <>
          <Header/>
          <Hero/>
        </>
        }
    </>
    )
}

export default Home