import React from 'react';
import purple from '../assets/purple.png';

function ImageTextCard() {
  return (
    <div className="flex items-center justify-center py-20 container mx-auto bg-red-500">
      <div className="flex shadow-md">
        <div className="bg-blue-200 rounded-tl-lg rounded-bl-lg">
          <img
            src={purple}
            alt="textcard"
            className="rounded-lg"
          />
        </div>

        <div className="bg-red-100 rounded-tr-lg rounded-br-lg px-4 py-2">
          <h2 className="text-lg font-medium text-blue-900">Title</h2>
          <p className="text-gray-600">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ImageTextCard;
