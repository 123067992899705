import React, { useState, useEffect, useContext, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ZoomVideo from '@zoom/videosdk'
import {  getDoc, doc } from "firebase/firestore"; 
import { firestore } from "../firebase";

import microphoneEnabledWhiteSVG from '../assets/microphone-enabled-white.svg';
import microphoneDisabledWhiteSVG from '../assets/microphone-disabled-white.svg';
import videoEnabledWhiteSVG from '../assets/video-enabled-white.svg';
import videoDisabledWhiteSVG from '../assets/video-disabled-white.svg';
import screenShareWhiteSVG from '../assets/share-white.svg';
import threeDotsWhiteSVG from '../assets/three-dots-white.svg';
import participantsWhiteSVG from '../assets/participants-white.svg';
import chatWhiteSVG from '../assets/chat-white.svg';
import sampleMP3 from '../assets/sample.mp3';
import { AuthContext } from '../AuthContext';

const VideoCall = () => {
  const { event_id } = useParams();
  const navigate = useNavigate();

  const [previewPageEnabled, setPreviewPageEnabled] = useState(true);

  const [previewVideoEnabled, setPreviewVideoEnabled] = useState(false);
  const [previewMicrophoneEnabled, setPreviewMicrophoneEnabled] = useState(false);

  const [localAudioInputTrackState, setLocalAudioInputTrackState] = useState(null);
  const [localAudioOutputTrackState, setLocalAudioOutputTrackState] = useState(null);
  const [localVideoTrackState, setLocalVideoTrackState] = useState(null);
  const localVideoTrackStateRef = useRef(localVideoTrackState);

  useEffect(() => {
    localVideoTrackStateRef.current = localVideoTrackState;
  }, [localVideoTrackState]);

  const [stream, setStream] = useState(null);
  const streamRef = useRef(stream);

  useEffect(() => {
    streamRef.current = stream;
  }, [stream]);

  const [audioInputDevices, setAudioInputDevices] = useState([]);
  const [audioOutputDevices, setAudioOutputDevices] = useState([]);
  const [videoDevices, setVideoDevices] = useState(null);
  const [volumeLevel, setVolumeLevel] = useState(0);

  const [roomVideoEnabled, setRoomVideoEnabled] = useState(null);
  const [roomMicrophoneEnabled, setRoomMicrophoneEnabled] = useState(null);
  const [roomScreenSharedEnabled, setRoomScreenSharedEnabled] = useState(false);
  const [roomChatClient, setRoomChatClient] = useState(null);

  const [roomVideoTrack, setRoomVideoTrack] = useState(null);
  const roomVideoTrackRef = useRef(roomVideoTrack);

  useEffect(() => {
    roomVideoTrackRef.current = roomVideoTrack;
  }, [roomVideoTrack]);

  const [roomSpeaker, setRoomSpeaker] = useState(null);
  const roomSpeakerRef = useRef(roomSpeaker);
  useEffect(() => {
    roomSpeakerRef.current = roomSpeaker;
  }, [roomSpeaker]);

  const [roomMicrophone, setRoomMicrophone] = useState(null);
  const roomMicrophoneRef = useRef(roomMicrophone);
  useEffect(() => {
    roomMicrophoneRef.current = roomMicrophone;
  }, [roomMicrophone]);

  const [userDevicesPermissionGrant, setUserDevicesPermissionGrant] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  
  const { currentUser, userData, loadedState } = useContext(AuthContext);

  let speakerTester = undefined;
  
  var client = ZoomVideo.createClient()

  useEffect(() => {
    if (loadedState) {
      console.log('loaded auth state!')
      console.log('event_id', event_id)
      console.log('userData.calendar[event_id]?.start_time', userData.calendar[event_id]?.host)
      console.log('userData.calendar[event_id]?.start_time', Date.now())
      console.log('userData.calendar[event_id]?.start_time', Date.now())
      if (!currentUser) {
        navigate('/login?event_id=' + event_id); // Adjust this path as necessary.

      } else if (currentUser && userData?.calendar[event_id]?.start_time < Date.now()) {
        console.log("Hello World1");
      } else if (currentUser && userData?.calendar[event_id]?.end_time < Date.now()) {
        console.log("Hello World2");
      }

    }
  }, [currentUser, loadedState, navigate, event_id, userData?.end_time, userData?.start_time, userData?.calendar]);

  useEffect(() => {

    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(() => {
        // Permission granted
        console.log('Permission granted');
        setUserDevicesPermissionGrant(true);
      })
      .catch((error) => { 
        if (error.name === 'NotAllowedError') {
          console.error('Permission denied');
          setUserDevicesPermissionGrant(false);
        } else {
          console.error('Error getting devices:', error);
        }
      });

      navigator.mediaDevices.enumerateDevices().then((devices) => {

      let videoinputs = devices.filter((device) => {
        return device.kind === 'videoinput'
      }).reverse()

      let audioinputs = devices
      .filter((device, index) => index !== 0 && device.kind === 'audioinput' && device.deviceId !== 'default')
      .map((device) => {
        return device;
      }).reverse();
  
      let audiooutputs =
      devices
      .filter((device, index) => index !== 0 && device.kind === 'audiooutput' && device.deviceId !== 'default')
      .map((device) => {
        return device;
      }).reverse();

      setLocalAudioInputTrackState(ZoomVideo.createLocalAudioTrack(audioinputs[0]?.deviceId))
      setLocalAudioOutputTrackState(audiooutputs[0])
      setLocalVideoTrackState(ZoomVideo.createLocalVideoTrack(videoinputs[0]?.deviceId))
      setAudioInputDevices(audioinputs);
      setAudioOutputDevices(audiooutputs);
      setVideoDevices(videoinputs);
      
      const videoSelectElement = document.getElementById('video-select');
      const microphoneSelectElement = document.getElementById('microphone-select');
      const speakerSelectElement = document.getElementById('speaker-select');
      
      videoSelectElement.innerHTML = '';
      microphoneSelectElement.innerHTML = ''; 
      speakerSelectElement.innerHTML = '';

      navigator.mediaDevices.ondevicechange = ((event) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {

        const videoInputs = devices.filter((device) => {
          return device.kind === 'videoinput'
        }).reverse()

        const audioInputs = devices
        .filter((device, index) => index !== 0 && device.kind === 'audioinput' && device.deviceId !== 'default')
        .map((device) => {
          return device;
        }).reverse()

        const audioOutputs = devices
        .filter((device, index) => index !== 0 && device.kind === 'audiooutput' && device.deviceId !== 'default')
        .map((device) => {
          return device;
        }).reverse()

        setAudioInputDevices(audioInputs)
        setAudioOutputDevices(audioOutputs)
        setVideoDevices(videoInputs)

        if (!videoInputs.includes(roomVideoTrack) && videoInputs.length === 1 && previewPageEnabled === false) {
          client.getMediaStream().switchCamera(videoInputs[0].deviceId)
          setRoomVideoTrack(videoInputs[0])
        }

        let videoDeviceIds = null;
        if (videoInputs.length > 0) {
          videoDeviceIds = videoInputs.map(device => device.deviceId);
          // Use the deviceIds array as needed
        }

        let audioInputDeviceIds = null;
        if (audioInputs.length > 0) {
          audioInputDeviceIds = audioInputs.map(device => device.deviceId);
        }

        let audioOutputDeviceIds = null;
        if (audioOutputs.length > 0) {
          audioOutputDeviceIds = audioOutputs.map(device => device.audioOutputs);
        }

        if (!videoDeviceIds?.includes(roomVideoTrackRef?.current?.deviceId) && streamRef.current && videoDeviceIds.length >= 1) {
          streamRef.current.stopVideo().then(() => {
            setRoomVideoTrack(videoInputs[0])
            setRoomVideoEnabled(false)
          }).catch((error) => {
            console.log(error)
          })
        }

        if (!audioInputDeviceIds?.includes(roomMicrophoneRef?.current?.deviceId) && streamRef.current && audioInputDeviceIds.length >= 1) {
            // roomSwitchMicrophone(audioInputs[0].label)
          streamRef.current.switchMicrophone(audioInputs[0].deviceId).then(() => {
            setRoomMicrophone(audioInputs[0])
            setRoomMicrophoneEnabled(false)
          })
        }
        
        if (!audioOutputDeviceIds?.includes(roomSpeakerRef?.current?.deviceId) && streamRef.current && audioOutputDeviceIds.length >= 1) {

          streamRef.current.switchSpeaker(audioOutputs[0].deviceId).then(() => {
            setRoomSpeaker(audioOutputs[0])
          })

        }

        if (!videoDeviceIds?.includes(localVideoTrackStateRef?.current?.deviceId)) {
          console.log('stopping room video')
          localVideoTrackStateRef.current.stop().then(() => {
            document.getElementById('preview-camera-video').srcObject = null;
            setLocalVideoTrackState(ZoomVideo.createLocalVideoTrack(videoInputs[0].deviceId))
            setPreviewVideoEnabled(false)
            // setRoomVideoEnabled(false)
          }).catch((error) => {
            console.log(error)
          })
        }

        if (speakerTester) {
          speakerTester.destroy();
          // eslint-disable-next-line
          speakerTester = undefined;
        }
        }
        )
      })

    }).catch((error) => {
      if (error.name === 'NotAllowedError') {
        console.error('Permission denied');
        setUserDevicesPermissionGrant(false);
        
      } else {
        console.error('Error getting devices:', error);
      }
    })
  }, []);

  useEffect(() => {
    if (userDevicesPermissionGrant === false) {
      const videoSelectElement = document.getElementById('video-select');
        const microphoneSelectElement = document.getElementById('microphone-select');
        const speakerSelectElement = document.getElementById('speaker-select');
        
        const optionElement = document.createElement('option');
        optionElement.textContent = 'Permission not granted by user';
        videoSelectElement.appendChild(optionElement);
        const optionElement2 = document.createElement('option');
        optionElement2.textContent = 'Permission not granted by user';
        microphoneSelectElement.appendChild(optionElement2);
        const optionElement3 = document.createElement('option');
        optionElement3.textContent = 'Permission not granted by user';
        speakerSelectElement.appendChild(optionElement3);
    }
  }
  , [userDevicesPermissionGrant])

  useEffect(() => {
    if (audioInputDevices && audioInputDevices?.length > 0) {
      const microphoneSelectElement = document.getElementById('microphone-select');
      const microphoneToggleBtn = document.getElementById('mic-toggle-btn');

      if (microphoneSelectElement) {
        microphoneSelectElement.disabled = false;
        microphoneSelectElement.style.cursor = 'pointer';
      }

      if (microphoneToggleBtn) {
        microphoneToggleBtn.disabled = false;
        microphoneToggleBtn.style.cursor = 'pointer';
      }

      let selectedLabel;

        if (microphoneSelectElement) {

        if (!previewPageEnabled && roomMicrophone) {
          selectedLabel = audioInputDevices.find(device => device.deviceId === roomMicrophone.deviceId)?.label;
        }
          else  if (previewPageEnabled) {
          selectedLabel = audioInputDevices.find(device => device.deviceId === localAudioInputTrackState.deviceId)?.label;
         }
         console.log(selectedLabel, 'audio selected label')

          while (microphoneSelectElement.firstChild) {
            microphoneSelectElement.removeChild(microphoneSelectElement.firstChild);
          }
          
          audioInputDevices.forEach((device) => {
            const optionElement = document.createElement('option');
            optionElement.textContent = device.label;
            microphoneSelectElement.appendChild(optionElement);
            if(selectedLabel) {
              const options = microphoneSelectElement.querySelectorAll('option');
              options.forEach((option) => {
                if (option.textContent === selectedLabel) {
                  console.log('selecting' + option.textContent)
                  option.selected = true;
                }
              });
            }
          });
          
        }
    } else {
      const microphoneSelectElement = document.getElementById('microphone-select');
      const microphoneToggleBtn = document.getElementById('mic-toggle-btn');
      console.log('mic devices not found')
      if (microphoneSelectElement) {
        microphoneSelectElement.disabled = true;
        microphoneSelectElement.style.cursor = 'not-allowed';
        while (microphoneSelectElement.firstChild) {
          microphoneSelectElement.removeChild(microphoneSelectElement.firstChild);
        }
        setPreviewMicrophoneEnabled(false);
      }
      if (microphoneToggleBtn) {
        microphoneToggleBtn.disabled = true;
        microphoneToggleBtn.style.cursor = 'not-allowed';
      }

    }

    // eslint-disable-next-line
  }, [audioInputDevices, roomMicrophone]);

  useEffect(() => {
    if (audioOutputDevices && audioOutputDevices?.length > 0) {
      const speakerSelectElement = document.getElementById('speaker-select')

      if (speakerSelectElement) {
        speakerSelectElement.disabled = false;
        speakerSelectElement.style.cursor = 'pointer';
      }

      
      let selectedLabel;

      if (previewPageEnabled) {
         selectedLabel = audioOutputDevices.find(device => device.deviceId === localAudioOutputTrackState.deviceId)?.label;

      } else if (!previewPageEnabled && roomSpeaker) {
        // console.log('roomSpeaker', roomSpeaker)
         selectedLabel = audioOutputDevices.find(device => device.deviceId === roomSpeaker.deviceId)?.label;
      }


      while (speakerSelectElement.firstChild) {
          speakerSelectElement.removeChild(speakerSelectElement.firstChild);
        }
        audioOutputDevices.forEach((device) => {
          const optionElement = document.createElement('option');
          optionElement.textContent = device.label;
          speakerSelectElement.appendChild(optionElement);
          
          if(selectedLabel) {
            const options = speakerSelectElement.querySelectorAll('option');
            options.forEach((option) => {
              if (option.textContent === selectedLabel) {
                // console.log('selecting ' + option.textContent)
                option.selected = true;
              }
            });
          }

        });
      // }
    }else {
      const speakerSelectElement = document.getElementById('speaker-select')
      if (speakerSelectElement) {
        speakerSelectElement.disabled = true;
        speakerSelectElement.style.cursor = 'not-allowed';
        while (speakerSelectElement.firstChild) {
          speakerSelectElement.removeChild(speakerSelectElement.firstChild);
        }
      }

    }
  // eslint-disable-next-line
  }, [audioOutputDevices, roomSpeaker]);
  
  useEffect(() => {
    if (videoDevices && videoDevices?.length > 0) {
      const videoSelectElement = document.getElementById('video-select')
      const videoToggleBtn = document.getElementById('video-toggle-btn');
      let selectedLabel = null;

      if (!previewPageEnabled && roomVideoTrack) {
        console.log('video devices found')
        selectedLabel = videoDevices.find(device => device.deviceId === roomVideoTrackRef.current.deviceId)?.label;
      } else if (previewPageEnabled){
        selectedLabel = videoDevices.find(device => device.deviceId === localVideoTrackState.deviceId)?.label;
      }

      if (videoSelectElement) {
        videoSelectElement.disabled = false;
        videoSelectElement.style.cursor = 'auto';
      }

      if (videoToggleBtn) {
        videoToggleBtn.disabled = false;
        videoToggleBtn.style.cursor = 'auto';
      }

      if (videoSelectElement) {
        while (videoSelectElement.firstChild) {
          videoSelectElement.removeChild(videoSelectElement.firstChild);
        }
        videoDevices.forEach((device) => {
          const optionElement = document.createElement('option');
          optionElement.textContent = device.label;
          videoSelectElement.appendChild(optionElement);
          if(selectedLabel) {
            const options = videoSelectElement.querySelectorAll('option');
            options.forEach((option) => {
              if (option.textContent === selectedLabel) {
                // console.log('selecting' + option.textContent)
                option.selected = true;
              }
            });
          } 
        });  
      } else {
        const optionElement = document.createElement('option');
        optionElement.textContent = "Video not found"
        videoSelectElement.appendChild(optionElement);

      }
    } else if (!videoDevices && previewPageEnabled) {
      const videoSelectElement = document.getElementById('video-select');
      const videoToggleBtn = document.getElementById('video-toggle-btn');
      console.log('video devices not found')
      if (videoSelectElement) {
        videoSelectElement.disabled = true;
        videoSelectElement.style.cursor = 'not-allowed';
        while (videoSelectElement.firstChild) {
          videoSelectElement.removeChild(videoSelectElement.firstChild);
        }
        setPreviewVideoEnabled(false);
      }
      if (videoToggleBtn) {
        videoToggleBtn.disabled = true;
        videoToggleBtn.style.cursor = 'not-allowed';
      }

    }
  // eslint-disable-next-line
  }, [videoDevices]);

  useEffect (() => {
    if (localVideoTrackState && previewVideoEnabled && previewPageEnabled) {
      // console.log('localVideoTrackState222', localVideoTrackState)
      localVideoTrackState.start(document.getElementById('preview-camera-video'))

      } else if (localVideoTrackState && !previewVideoEnabled && previewPageEnabled) {
        if (localVideoTrackState.isVideoStarted) {
          localVideoTrackState.stop()
          .catch((error) => {
          console.error('Error starting video track:', error);
        })
        document.getElementById('preview-camera-video').srcObject = null;

      }
  }
  
  }, [localVideoTrackState, previewVideoEnabled, previewPageEnabled])

  // let volumeInterval;
  let volumeInterval = null;

  useEffect(() => {

    // console.log('localAudioInputTrackState changed', localAudioInputTrackState)

    if (volumeInterval) {
      clearInterval(volumeInterval);
      document.getElementById("mic-input-level").reset();
    }
    
    // if (localAudioInputTrackState && previewMicrophoneEnabled) {

      if (localAudioInputTrackState && !localAudioInputTrackState?.isAudioStarted  && previewMicrophoneEnabled) {

        console.log('starting local audio input', localAudioInputTrackState)
          localAudioInputTrackState.start()
          .then(() => {
            localAudioInputTrackState.unmute();
          //   console.log('unmuting local audio input', localAudioInputTrackState)
          })
          // eslint-disable-next-line
          volumeInterval = setInterval(() => setVolumeLevel(localAudioInputTrackState.getCurrentVolume() * 1000), 50);
      }

      if (localAudioInputTrackState?.isAudioStarted && previewMicrophoneEnabled) {
        localAudioInputTrackState.unmute();
        console.log('unmuting local audio input', localAudioInputTrackState)
      } else if (localAudioInputTrackState?.isAudioStarted && !previewMicrophoneEnabled) {
        localAudioInputTrackState.mute();
      }

  }, [localAudioInputTrackState, previewMicrophoneEnabled]); 

  
function switchVideo(deviceName) {
    const selectedDevice = videoDevices.find(device => device.label === deviceName);
    setLocalVideoTrackState(ZoomVideo.createLocalVideoTrack(selectedDevice.deviceId))
    console.log('selectedDevice', selectedDevice)
}


function roomSwitchVideo(deviceName) {
    const selectedDevice = videoDevices.find(device => device.label === deviceName);
    console.log('switching room video to selectedDevice', selectedDevice)
    stream.switchCamera(selectedDevice.deviceId)
    setRoomVideoTrack(selectedDevice)
}

function roomSwitchMicrophone(deviceName) {
  const selectedDevice = audioInputDevices.find(device => device.label === deviceName);
  console.log('roomSwitchMicrophone deviceName', deviceName)
  console.log('roomSwitchMicrophone audioinputdevices', audioInputDevices)
  console.log('switching room mic to selectedDevice', selectedDevice)
  stream.switchMicrophone(selectedDevice.deviceId)
  setRoomMicrophone(selectedDevice)
}

function roomSwitchSpeaker(deviceName) {
  const selectedDevice = audioOutputDevices.find(device => device.label === deviceName);
  console.log('switching room speaker to selectedDevice', selectedDevice)
  stream.switchSpeaker(selectedDevice.deviceId)
  setRoomSpeaker(selectedDevice)
}

function switchMicrophone(deviceName) {
  console.log('switch initiated for ' + deviceName.device)

  if (localAudioInputTrackState?.isAudioStarted) {
    console.log('stopping local audio input')
    localAudioInputTrackState.stop()
  }

  const selectedDevice = audioInputDevices.find(device => device.label === deviceName);
  console.log('switching local audio input to selectedDevice', selectedDevice)
  if (selectedDevice) {
    setLocalAudioInputTrackState(ZoomVideo.createLocalAudioTrack(selectedDevice.deviceId))
  }
}

function switchSpeaker(deviceName) {
  if (speakerTester) {
        speakerTester.destroy();
        speakerTester = undefined;
  }
  const selectedDevice = audioOutputDevices.find(device => device.label === deviceName);
  console.log('switching local audio output to selectedDevice', selectedDevice)
  setLocalAudioOutputTrackState(selectedDevice)
}

function startPreviewAudioButton() {
    setPreviewMicrophoneEnabled(true);
}

function stopPreviewAudioButton() {
    setPreviewMicrophoneEnabled(false);
}
 
function startPreviewVideoButton() {
    setPreviewVideoEnabled(true);
}

function stopPreviewVideoButton() {
    setPreviewVideoEnabled(false);
}

function stopRoomVideoButton() {
  console.log('stopping room video')
    setRoomVideoEnabled(false);
}

function startRoomVideoButton() {
  console.log('starting room video')
    setRoomVideoEnabled(true);
}

function stopRoomMicrophoneButton() {
  console.log('stopping room microphone')
    setRoomMicrophoneEnabled(false);
}

function startRoomMicrophoneButton() {
  console.log('starting room microphone')
    setRoomMicrophoneEnabled(true);
}

function startRoomScreenShare() {
  console.log('starting room screenshare')
  setRoomScreenSharedEnabled(true);
}

function stopRoomScreenShare() {
  console.log('stopping room screenshare')
  setRoomScreenSharedEnabled(false);
}



useEffect(() => {
  if (stream && roomVideoEnabled) {
    if (stream.isRenderSelfViewWithVideoElement()) {
      console.log('rendered with video element')
      let videoElement = document.getElementById('video-' + currentUser.uid);

      if (!videoElement) {
        videoElement = document.createElement('video');
        videoElement.id = 'video-' + currentUser.uid;
        videoElement.className = 'bg-white p-10';
        videoElement.width = 1920;
        videoElement.height = 1080;
        document.getElementById('self-video').appendChild(videoElement);
      }

      let userElement = document.getElementById('user-name-' + currentUser.uid);
      if (!userElement) {
        userElement = document.createElement('p');
        userElement.id = 'user-name-' + currentUser.uid;
        userElement.className = 'text-white';
        userElement.innerText = userData.Fname + ' ' + userData.Lname;
        document.getElementById('self-video').appendChild(userElement);
      }
          
          stream
          .startVideo({ cameraId: roomVideoTrack.deviceId, videoElement: document.getElementById('video-' + currentUser.uid) })
          .then(() => {
            // video successfully started and rendered
            
          })
          .catch((error) => {
            console.log(error);
            streamRef.current.stopVideo().then(() => {
              console.log('Video stopped')
              setRoomVideoEnabled(false)
            }).catch((error) => {
              console.log(error)
            })
          });  

    } else {
      let canvasElement = document.getElementById('video-' + currentUser.uid);
      if (!canvasElement) {
        canvasElement.id = 'video-' + currentUser.uid;
        canvasElement.className = 'bg-white p-10';
        canvasElement.width = 1920;
        canvasElement.height = 1080;
        document.getElementById('self-video').appendChild(canvasElement);
      }


      let userElement = document.getElementById('user-name-' + currentUser.uid);
      if (!userElement) {
        userElement = document.createElement('p');
        userElement.id = 'user-name-' + currentUser.uid;
        userElement.className = 'text-white';
        userElement.innerText = userData.Fname + ' ' + userData.Lname;
      document.getElementById('self-video').appendChild(userElement);
      }

      stream
        .startVideo({  cameraId: roomVideoTrack.deviceId, videoElement: document.getElementById('video-' + currentUser.uid) })
        .then(() => {
          stream
            .renderVideo(
              document.getElementById('video-' + currentUser.uid),
              currentUser.uid, 1920, 1080, 0, 0, 3)
            .then(() => {
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
          

        
    }
  } else if (stream && !roomVideoEnabled) {
    if (stream.isRenderSelfViewWithVideoElement()) {
      let videoElement = document.getElementById('video-' + currentUser.uid);

      if (!videoElement) {
        videoElement = document.createElement('video');
        videoElement.id = 'video-' + currentUser.uid;
        videoElement.className = 'bg-white p-10';
        videoElement.width = 1920;
        videoElement.height = 1080;
        document.getElementById('self-video')?.appendChild(videoElement);
      }

      let userElement = document.getElementById('user-name-' + currentUser.uid);
      if (!userElement) {
        userElement = document.createElement('p');
        userElement.id = 'user-name-' + currentUser.uid;
        userElement.className = 'text-white';
        userElement.innerText = userData.Fname + ' ' + userData.Lname;
      document.getElementById('self-video')?.appendChild(userElement);
      }

    } else {
      let canvasElement = document.getElementById('video-' + currentUser.uid);
      if (!canvasElement) {
        canvasElement.id = 'video-' + currentUser.uid;
        canvasElement.className = 'bg-white p-10';
        canvasElement.width = 1920;
        canvasElement.height = 1080;
        document.getElementById('self-video').appendChild(canvasElement);
      }
      let userElement = document.getElementById('user-name-' + currentUser.uid);
      if (!userElement) {
        userElement = document.createElement('p');
        userElement.id = 'user-name-' + currentUser.uid;
        userElement.className = 'text-white';
        userElement.innerText = userData.Fname + ' ' + userData.Lname;
      document.getElementById('self-video').appendChild(userElement);
      }

    }

    stream.stopVideo().then(() => {
      console.log('Video stopped')
    }).catch((error) => {
      console.log(error)
    })
  }
// eslint-disable-next-line
}, [stream, client, roomVideoEnabled]);

// declare audio initialization state
let audioDecode
let audioEncode
client.on('media-sdk-change', (payload) => {
  if (payload.type === 'audio' && payload.result === 'success') {
    if (payload.action === 'encode') {
      // encode for sending audio stream (speak)
      audioEncode = true
    } else if (payload.action === 'decode') {
      // decode for receiving audio stream (hear)
      audioDecode = true
    }
  }
})

useEffect(() => {
  
  if (stream && roomMicrophoneEnabled) {
    streamRef.current.unmuteAudio().then(() => {
      console.log('unmuting audio')
    }).catch((error) => {
      console.log(error)
    })    
  
  } else if (stream && !roomMicrophoneEnabled) {
    streamRef.current.muteAudio().then(() => {
        console.log('muting audio')
      }).catch((error) => {
        console.log(error)
      })    
  }

}, [stream, roomMicrophoneEnabled]);

useEffect(() => {
  if (stream) {
    client.on('peer-video-state-change', (payload) => {

      if (payload.action === 'Start') {
        console.log('rendering video for user: ' + payload.userId + ' in the canvas element with id: participant-videos-canvas');


        client.getAllUser().forEach((user) => {
          if (payload.userId === user.userId) {
            console.log('get value dad2d121e312', user.userIdentity)
            const peerVideo = document.getElementById('video-' + user.userIdentity)
            if (peerVideo) {
              stream.renderVideo(peerVideo, payload.userId, 1920, 1080, 0, 0, 3);
            }
          }
        })

        // document.getElementById('participant-videos-canvas-name').innerText = payload.name;
        
      } else if (payload.action === 'Stop') {

        client.getAllUser().forEach((user) => {
          if (payload.userId === user.userId) {
            console.log('get value dad2d121e312', user.userIdentity)

            stream.stopRenderVideo(document.getElementById('video-' + user.userIdentity), payload.userId);
          }
        })
      }
    });



  }
}, [client, stream]);

useEffect(() => {

  if(roomScreenSharedEnabled && stream) {
    if (stream.isStartShareScreenWithVideoElement()) {
      stream
        .startShareScreen(document.querySelector('#my-screen-share-content-video'))
        .then(() => {
          // screen share successfully started and rendered
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      stream
        .startShareScreen(document.querySelector('#my-screen-share-content-canvas'))
        .then(() => {
          // screen share successfully started and rendered
        })
        .catch((error) => {
          console.log(error)
        })
    }
  } else if (!roomScreenSharedEnabled && stream) {
    stream.stopShareScreen().then(() => {
      const canvas = document.querySelector('#my-screen-share-content-canvas');
      canvas.srcObject = null;
      const video = document.querySelector('#my-screen-share-content-video');
      video.srcObject = null;
      console.log('Screen share stopped')
    }).catch((error) => {
      console.log(error)
    })

  }


}, [roomScreenSharedEnabled, stream]);


const joinCall = async () => {
      setPreviewPageEnabled(false)
      ZoomVideo.getDevices().then((devices) => {
        let videoinputs = devices.filter((device) => {
          console.log(device.label, device.deviceId)
          return device.kind === 'videoinput'
        })
  
        let audioinputs = devices
        .filter((device) => device.kind === 'audioinput')
        .map((device) => {
          console.log(device.label)
          return device;
        });
    
        let audiooutputs =
        devices
        .filter((device) => device.kind === 'audiooutput')
        .filter((device, _, array) => {
          // Check if the device is a default device
          if (device.label.startsWith('Default -')) {
            return true; // Always keep default devices
          } else {
            // Check if there is a default device with the same name (excluding the 'Default -' part)
            const defaultDeviceLabel = 'Default - ' + device.label;
            // If there is no corresponding default device, keep this device
            return !array.find((d) => d.label === defaultDeviceLabel);
          }
        })
        .map((device) => {
          console.log(device.label)
          return device;
        });
        setAudioInputDevices(audioinputs);
        setAudioOutputDevices(audiooutputs);
        setVideoDevices(videoinputs);
      
      })
      console.log('joining call', currentUser.uid === userData.calendar[event_id]?.host ? 1 : 0)
      // console.log('currentUser.uid', currentUser.uid)
      // console.log('userData.calendar[event_id]?.host', userData.calendar[event_id]?.host)
      const jwtConfig = {
        topic: event_id,
        name: userData.firstName + ' ' + userData.lastName,
        sessionKey: event_id,
        roleType: currentUser.uid === userData.calendar[event_id]?.host ? 1 : 0
      }

      let response = await fetch('https://zoom-access-token-server-6jclwsdvxa-uk.a.run.app/generate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(jwtConfig)
      });

      let data = await response.json();

      if(ZoomVideo.checkSystemRequirements().video && ZoomVideo.checkSystemRequirements().audio) {
        client.init('en-US', 'Global', { patchJsMedia: true }).then(() => {
          client.join(event_id, data, currentUser.uid).then(() => {
            let isSafari = window.safari !== undefined

            if(isSafari) {
              // desktop Safari, check if desktop Safari has initialized audio
              if(audioEncode && audioDecode){
                // desktop Safari has initialized audio, continue to start audio
                client.getMediaStream().startAudio({microphoneId: localAudioInputTrackState}).then(() => {
                  console.log('starting audio safari')

                  if (previewMicrophoneEnabled) {
                    client.getMediaStream().unmuteAudio().then(() => {
                      console.log('unmuting audio')
                    }).catch((error) => {
                      console.log(error)
                    })    
                  
                  } else if (!previewMicrophoneEnabled) {
                    client.getMediaStream().muteAudio().then(() => {
                        console.log('muting audio')
                      }).catch((error) => {
                        console.log(error)
                      })    
                  }
                  // setAudioStarted(true)
                })


              } else {
                // desktop Safari has not initialized audio, retry or handle error
                console.log('safari audio has not finished initializing')
              }
          
            } else {
              // not desktop Safari, continue to start audio
              client.getMediaStream().startAudio({microphoneId: localAudioInputTrackState.deviceId}).then(() => {
                console.log('starting audio not safari')
                // setAudioStarted(true)
                if (previewMicrophoneEnabled) {
                  client.getMediaStream().unmuteAudio().then(() => {
                    console.log('unmuting audio')
                  }).catch((error) => {
                    console.log(error)
                  })    
                
                } else if (!previewMicrophoneEnabled) {
                  client.getMediaStream().muteAudio().then(() => {
                      console.log('muting audio')
                    }).catch((error) => {
                      console.log(error)
                    })    
                }
                })
            }

            setRoomChatClient(client.getChatClient())
            setStream(client.getMediaStream())
            setRoomVideoEnabled(previewVideoEnabled);
            console.log('setting roomvideotrack to localvideotrackstate', localVideoTrackState)
            console.log(client.getAllUser(), 'all users')
            setRoomVideoTrack(localVideoTrackState)
            setRoomMicrophoneEnabled(previewMicrophoneEnabled);
            setRoomMicrophone(localAudioInputTrackState)
            roomSwitchMicrophone(localAudioInputTrackState?.label)
            setRoomSpeaker(localAudioOutputTrackState)

            client.getAllUser().forEach((user) => {
              console.log('get value ', user.userIdentity)

              // for each useridentity, make a canvas and subsequently render the video element if their video is on.

              // Create a canvas element
              if (user.userIdentity !== currentUser.uid) {
                if (!document.getElementById('video-' + user.userIdentity)) {
                  const canvas = document.createElement('canvas');
                  // Set the id of the element to be the user's identity
                  canvas.id = 'video-' + user.userIdentity;
                  canvas.className = 'bg-white p-10';
                  canvas.width = 1920;
                  canvas.height = 1080;
                  // Append the canvas element to the participant-videos div
                  document.getElementById('participant-videos').appendChild(canvas);  
                }
                
                if(user.bVideoOn) {
                  client.getMediaStream().renderVideo(document.getElementById('video-' + user.userIdentity), user.userId, 1920, 1080, 0, 0, 3);
                  // document.getElementById('participant-videos-canvas-name').innerText = user.userIdentity;
                }

                if (user.sharerOn) {
                  client.getMediaStream().startShareView(
                    document.querySelector('#participants-screen-share-content-canvas'),
                    user.userId
                  )
                }              
            }



           })

          }).catch((error) => {
            console.log(error)
          })
        }).catch((error) => {
          console.log(error)
        })
    }

    client.on('user-added', async (payload) => {
      client.getAllUser().forEach(async (user) => {
        if (payload[0].userId === user.userId && user.userIdentity !== currentUser.uid) {
          if (!document.getElementById('video-' + user.userIdentity) && !document.getElementById('participant-name-' + user.userIdentity)) {
            const canvas = document.createElement('canvas');
            // Set the id of the element to be the user's identity
            canvas.id = 'video-' + user.userIdentity;
            canvas.className = 'bg-white p-10';
            canvas.width = 1920;
            canvas.height = 1080;
            // Append the canvas element to the participant-videos div
            document.getElementById('participant-videos').appendChild(canvas);

            const participantName = document.createElement('p');
            participantName.id = 'participant-name-' + user.userIdentity;
            participantName.className = 'text-white';

            const userDocRef = doc(firestore, 'users', user.userIdentity);
            
            getDoc(userDocRef).then((docSnap) => {
              if (docSnap.exists()) {
                const userData = docSnap.data();
                participantName.innerText = userData.Fname + ' ' + userData.Lname;
                // Append the participant name to the 'participant-videos' div
                document.getElementById('participant-videos').appendChild(participantName);
              } else {
                console.log("No such document!");
              }
            }).catch((error) => {
              console.error("Error getting document:", error);
            });
            
            // console.log(userDocs.docs[0].data()['Fname'])
            // if (!userDocs.empty) {
            //   // console.log('(!@(!)@*&!)(*@&!()@')
            //   console.log(userDocs.docs[0].data().Fname)
            // }
            document.getElementById('participant-videos').appendChild(participantName);
          }
        }
      });
    });
  
    client.on('user-removed', (payload) => {
      if (payload && payload.length > 0) {
        console.log(Object.keys(payload), 'user removed');
        }
    });

    
    client.on('user-updated', (payload) => {
      // console.log(payload[0].userId + ' properties were updated')
      client.getAllUser().forEach((user) => {
        if (user.isInFailover) {
          console.log('user is in failover', user)
          const videoElement = document.getElementById('video-' + user.userIdentity);
          if (videoElement) {
            videoElement.remove();
          }
          const nameElement = document.getElementById('participant-name-' + user.userIdentity);
          if (nameElement) {
            nameElement.remove();
          }

        }
      })
      // console.log(Object.keys(payload[0]));
    })

    client.on('passively-stop-share', (payload) => {
      console.log(payload)
      setRoomScreenSharedEnabled(false)
    })

    client.on('user-left', (payload) => {
      console.log('user disconnected', payload);
      // Perform additional actions here, such as logging the disconnect event to a server
  });

    client.on('connection-change', (payload) => {
      if(payload.state === 'Closed') {
        // session ended by the host or the SDK kicked the user from the session (use payload.reason to see why the SDK kicked the user)
        console.log('disconnected user', payload) 
      } else if(payload.state === 'Reconnecting') {
        // the client side has lost connection with the server (like when driving through a tunnel)
        // will try to reconnect for a few minutes
        console.log('reconnecting', payload)
      } else if(payload.state === 'Connected') {
        // SDK reconnected the session after a reconnecting state
      } else if(payload.state === 'Fail') {
        // session failed to reconnect after a few minutes
        // user flushed from Zoom Video SDK session
      }
    })
    
    client.on('active-speaker', (payload) => {
      console.log('Active speaker, use for CSS visuals', payload) // new active speaker, for example, use for microphone visuals, css video border, etc.
      const elements = document.getElementById(payload.userId);
      console.log(elements)
   })

   client.on('active-share-change', (payload) => {
    if (payload.state === 'Active') {
      client.getMediaStream().startShareView(
        document.querySelector('#participants-screen-share-content-canvas'),
        payload.userId
      )
    } else if (payload.state === 'Inactive') {
      client.getMediaStream().stopShareView()
    }
  })

  client.on('chat-on-message', (payload) => {
    console.log(payload)
    console.log(`Message: ${payload.message}, from ${payload.sender.name} to ${payload.receiver.name}`)
    const chatMessage = document.createElement("p");
    chatMessage.style.backgroundColor = "red";
    chatMessage.textContent = `${payload.sender.name}: ${payload.message}`;
    document.getElementById('chat-messages').append(chatMessage);

  })
    }
  
  function testSpeaker() {
    if (localAudioInputTrackState && !speakerTester) {
      const outputLevelElm = document.querySelector("#speaker-output-level");
      speakerTester = localAudioInputTrackState.testSpeaker({
        speakerId: localAudioOutputTrackState.deviceId,
        sampleAudioUrl: sampleMP3,
        onAnalyseFrequency: (v) => {
          console.log(v);
          outputLevelElm.value = v;
        },
      });

      const audioDurationMs = 3250; // Duration in milliseconds

      setTimeout(() => {
        if (speakerTester) {
          speakerTester.destroy();
          speakerTester = undefined;
          console.log('Speaker tester destroyed after one loop.');
        }
      }, audioDurationMs);
    }
  }

  function sendChatMessage() {
    if (document.getElementById('chat-box-text-input').value) {
      roomChatClient.sendToAll(document.getElementById('chat-box-text-input').value);
      document.getElementById('chat-box-text-input').value = null;
    } else {
      return
    }
  }

  function displayPermissionGrantHelp() {
    alert('Please grant permission to use your devices')
  }

// useEffect(() => {
//   const userListElement = document.getElementById('user-list');
//   console.log('users', users)

//   // Clear the user list
//   while (userListElement?.firstChild) {
//     userListElement.removeChild(userListElement.firstChild);
//   }
//   // Add new users to the list
//   users?.filter(user => !user.isInFailover).forEach(user => {
//     const li = document.createElement('li');
//     li.className = 'relative';
//     li.textContent = user.userIdentity;
//     li.key = user.userId;

//     const button = document.createElement('button');
//     button.textContent = 'Options';
//     button.onclick = (event) => {
//       // Your existing click event handler code here
//     };

//     const div = document.createElement('div');
//     div.className = 'absolute bg-white text-black py-2 mt-2 rounded shadow-md opacity-0 pointer-events-none';

//     const muteUserP = document.createElement('p');
//     muteUserP.className = 'px-4 py-2 hover:bg-gray-200 cursor-pointer';
//     muteUserP.textContent = 'Mute User';
//     muteUserP.onclick = () => console.log('Mute User' + user.userId);

//     const removeFromCallP = document.createElement('p');
//     removeFromCallP.className = 'px-4 py-2 hover:bg-gray-200 cursor-pointer';
//     removeFromCallP.textContent = 'Remove from Call';
//     removeFromCallP.onclick = () => console.log('Remove from Call');

//     div.appendChild(muteUserP);
//     div.appendChild(removeFromCallP);

//     li.appendChild(button);
//     li.appendChild(div);

//     userListElement.appendChild(li);
//   });
// }, [users]);

const dropdownRef = useRef(null);

useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(false);
    }
  };

  // Bind the event listener
  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    // Unbind the event listener on clean up
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, []);


const hostMuteUser = (userId) => {
    stream?.muteAudio(userId)
    console.log('muting user', userId)
}

const hostRemoveUser = (userId) => {
    stream?.removeUser(userId)
    console.log('removing user', userId)
}

  return (
    <>
    {previewPageEnabled ? (
      <>
    <label htmlFor="mic-input-level">Input level:</label>
    <progress id="mic-input-level" max="100" value={localAudioInputTrackState?.getCurrentVolume() * 1000}></progress>
    <br/>
    <label htmlFor="mic-input-level">Input level:</label>
    <progress id="mic-input-level" max="100" value={volumeLevel}></progress>
    <br/>
    <p>{currentUser?.email}</p>
    <p>{currentUser?.uid}</p>
    <p>{userData?.Fname}</p>
    <p>{userData?.Lname}</p>
    <p>{loadedState}</p>
    <p>{localAudioInputTrackState?.deviceId}</p>
    <p>{localAudioInputTrackState?.label}</p>
    <br/>
    <br/>
    <label htmlFor="speaker-output-level">Output level:</label>
    <progress id="speaker-output-level" max="100" value="0"></progress>
    <button onClick={testSpeaker}>Test Speaker</button>
      <div className="flex justify-center items-center h-screen"> 
      <div className="bg-gray-500 ml-24 flex-grow rounded-xl aspect-w-16 aspect-h-9">
        <p className='text-center text-white'>{previewVideoEnabled ? 'Camera is starting': 'Camera is off'}</p>
        {userDevicesPermissionGrant === false ? <button onClick={displayPermissionGrantHelp} className='text-center text-black bg-red-300'>Permission not granted by user</button> : null}
        <video id="preview-camera-video" className="rounded-xl opacity-25" />

      <div className='flex justify-center'>
       
      <button
          id="mic-toggle-btn"
          className="w-12 color white cursor-pointer rounded-full border border-white"
          onClick={previewMicrophoneEnabled ? stopPreviewAudioButton : startPreviewAudioButton}
      >
        <img 
          src={previewMicrophoneEnabled ? microphoneEnabledWhiteSVG : microphoneDisabledWhiteSVG}
          alt={previewMicrophoneEnabled ? "microphone-enabled" : "microphone-disabled"}
        />
      </button>

      <button
        id="video-toggle-btn"
        className="w-12 color white cursor-pointer rounded-full border border-white"
        onClick={previewVideoEnabled ? stopPreviewVideoButton : startPreviewVideoButton}
      >
        <img 
          src={previewVideoEnabled ? videoEnabledWhiteSVG : videoDisabledWhiteSVG} 
          alt={previewVideoEnabled ? "video-enabled" : "video-disabled"} 
        />
      </button>

      {/* <button
        id="video-effects-btn"
        className="w-12 color white cursor-pointer rounded-full border border-white"
        onClick={videoEffects}
      >
        <img 
          src={visual}
          alt={"video-visual-icon"} 
        />
      </button> */}

{/* {toggleVideoEffectsScreen ? (

      <div 
        id="myCanvas" 
        width="800" 
        height="600" 
        className="fixed inset-0 w-1/2 h-1/2 bg-red-500 z-50 flex justify-center items-center mx-auto"
      >

          <div>
            <video id="preview-effects-camera-video" className="rounded-xl opacity-25 flex-grow w-full h-full block" />

            <h1>Backgrounds</h1>
            <img src={purple} alt=''  onClick={() => setLocalVirtualBackground()} className='w-48 cursor-pointer'></img>
            <img src={purple_2} alt='' className='w-48'></img>
    
            <h1>Blur and personal backgrounds</h1>
            <img src={bgblur} alt='' className='w-48'></img>
    
          </div>
      </div>
      ) : null} */}
      
      <select id='video-select' className="rounded-full text-white p-4 bg-blue-500" onChange={(event) => switchVideo(event.target.value)} />
      <select id='microphone-select'  className="cursor-pointer rounded-full text-white p-4 bg-blue-500"
      onChange={(event) => switchMicrophone(audioInputDevices,event.target.value)}
      />

      <select id='speaker-select' className="rounded-full text-white p-4 bg-blue-500"
      onChange={(event) => switchSpeaker(event.target.value)}
      />

      </div>

      </div>

      <div className="relative bg-green-500 w-1/2 flex flex-col justify-center items-center mr-96">

        <h1 className="text-4xl text-black">Ready to join?</h1>
        
        <button 
          onClick={joinCall} 
          className={`rounded-full text-white p-4 bg-blue-500 ${!loadedState ? 'cursor-not-allowed' : ''}`} disabled={!loadedState}>
          Join now
        </button>
      </div>

    </div>


      </>
    )
    : (
      <div className="bg-gray-800">
        <div id="self-video"></div>
        <div id='participant-videos'></div>

        <video id="my-screen-share-content-video" height="1080" width="1920"></video>
        <canvas id="my-screen-share-content-canvas" height="1080" width="1920"></canvas>
        <canvas id="participants-screen-share-content-canvas" height="1080" width="1920"></canvas>
        
        <div id='chat-box' className='bg-white'>
          <div id='chat-messages' className='bg-red'>

          </div>

        </div>
        <input id="chat-box-text-input" />
        <button className='bg-red' onClick={sendChatMessage}>Send Message</button>
        <footer className='flex justify-center text-white'>
          <div className="mr-4">
            <p>{event_id}</p>
          </div>
          <div className="mx-4 flex">
              <img
                className="w-12 color white cursor-pointer rounded-full border border-white"
                onClick={roomVideoEnabled ? stopRoomVideoButton : startRoomVideoButton}
                src={roomVideoEnabled ? videoEnabledWhiteSVG : videoDisabledWhiteSVG}
                alt={roomVideoEnabled ? "video-enabled" : "video-disabled"}
              />
              <img
                className="w-12 color white cursor-pointer rounded-full border border-white"
                onClick={roomMicrophoneEnabled ? stopRoomMicrophoneButton : startRoomMicrophoneButton}
                src={roomMicrophoneEnabled ? microphoneEnabledWhiteSVG : microphoneDisabledWhiteSVG}
                alt={roomMicrophoneEnabled ? "mic-enabled" : "mic-disabled"}
              />
              <img
                className="w-12 color white cursor-pointer rounded-full border border-white"
                onClick={roomScreenSharedEnabled ? stopRoomScreenShare : startRoomScreenShare}
                src={roomScreenSharedEnabled ? screenShareWhiteSVG : microphoneEnabledWhiteSVG}
                alt={roomScreenSharedEnabled ? "mic-enabled" : "mic-disabled"}              />
              <img
                className="w-12 color white cursor-pointer rounded-full border border-white"
                src={threeDotsWhiteSVG}
                alt="microphone-icon"
              />
          </div>
          <div className="flex ml-4">
            <img
                className="w-12 color white cursor-pointer rounded-full border border-white"
                src={participantsWhiteSVG}
                alt="microphone-icon"
            />
            <img
                className="w-12 color white cursor-pointer rounded-full border border-white"
                src={chatWhiteSVG}
                alt="microphone-icon"
            />

            <select id='video-select' className="rounded-full text-white p-4 bg-blue-500" onChange={(event) => roomSwitchVideo(event.target.value)} />

            <select id='microphone-select'  className="cursor-pointer rounded-full text-white p-4 bg-blue-500"
            onChange={(event) => roomSwitchMicrophone(event.target.value)}
            />

            <select id='speaker-select' className="rounded-full text-white p-4 bg-blue-500"
            onChange={(event) => roomSwitchSpeaker(event.target.value)}
            />
          </div>
        </footer>
        
        <div  className='bg-blue-200'>
          <h1>Users</h1>
          <ul id='user-list'>
      {client.getAllUser().filter(user => !user.isInFailover && user.displayName !== currentUser?.uid).map((user) => {
        return (
          
          <li id={user.displayName} key={user.displayName} className="relative flex">
            <p>{user.userIdentity}</p>
            {console.log('client.getAllUser()', client.getAllUser())}
            {console.log('currentUser?.uid', currentUser?.uid)}
            {console.log('user.displayName', user.displayName)}
            {currentUser?.uid === userData.calendar[event_id]?.host && (
                <button
                onClick={(event) => {
                  event.stopPropagation();
                  setDropdownVisible(!dropdownVisible);
                }}
              >
                Options
              </button>
            )}
            {currentUser.uid === userData.calendar[event_id]?.host ? 1 : 0}

            {dropdownVisible && (
              <div ref={dropdownRef} className="absolute bg-white text-black py-2 mt-2 rounded shadow-md">
                <p onClick={() => hostMuteUser(user.userId)} className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Mute User</p>
                <p onClick={() => hostRemoveUser(user.userId)} className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Remove from Call</p>
              </div>
              )}
          </li>
        );
      })}

          </ul>

        </div>

      </div>
    )}

    </>

  )
}
export default VideoCall
