import React from 'react';

function Footer() {
  return (
    <footer className="bg-gray-900 text-gray-500 py-8">

      <div className="bg-red-100 container max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
        
        <div className="bg-green-100 grid grid-cols-1 md:grid-cols-4 gap-8 text-center">
          <div className="mb-8 md:mb-0">
            <h3 className="text-lg font-medium mb-4">Column 1</h3>
            <ul className="list-none">
              <li><a href="/" className="hover:text-gray-300">Link 1</a></li>
              <li><a href="/" className="hover:text-gray-300">Link 2</a></li>
              <li><a href="/" className="hover:text-gray-300">Link 3</a></li>
            </ul>
          </div>
        
          <div className="mb-8 md:mb-0">
            <h3 className="text-lg font-medium mb-4">Column 2</h3>
            <ul className="list-none">
              <li><a href="/" className="hover:text-gray-300">Link 4</a></li>
              <li><a href="/" className="hover:text-gray-300">Link 5</a></li>
              <li><a href="/" className="hover:text-gray-300">Link 6</a></li>
            </ul>
          </div>
        
          <div className='items-center'>
            <h3 className="text-lg font-medium mb-4">Column 3</h3>
            <ul className="list-none">
              <li><a href="/" className="hover:text-gray-300">Link 7</a></li>
              <li><a href="/" className="hover:text-gray-300">Link 8</a></li>
              <li><a href="/" className="hover:text-gray-300">Link 9</a></li>
            </ul>
          </div>        

          <div className='items-center'>
            <h3 className="text-lg font-medium mb-4">Column 4</h3>
            <ul className="list-none">
              <li><a href="/" className="hover:text-gray-300">Link 7</a></li>
              <li><a href="/" className="hover:text-gray-300">Link 8</a></li>
              <li><a href="/" className="hover:text-gray-300">Link 9</a></li>
            </ul>
          </div>
        
        </div>
      
      </div>
    
    </footer>
  );
}

export default Footer;
