import React from 'react';
import purple from '../assets/purple.png';

const CoachPanel = () => {
  const images = [
    {
      url: {purple},
      text: 'Image 1 description',
    },
    {
      url: {purple},
      text: 'Image 2 description',
    },
    {
      url: {purple},
      text: 'Image 3 description',
    },
    {
      url: {purple},
      text: 'Image 4 description',
    },
    {
      url: {purple},
      text: 'Image 5 description',
    },
    {
      url: {purple},
      text: 'Image 6 description',
    },
    {
      url: {purple},
      text: 'Image 7 description',
    },
    {
      url: {purple},
      text: 'Image 8 description',
    },
  ];

  return (
    <div className='bg-blue-100'>
      <div className="container mx-auto px-8 py-8">
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8">
          {images.map((image, index) => (
            <div key={index} className="flex flex-col items-center">
              <img className="w-full rounded-lg" src={image.url} alt="" />
              <p className="bg-red-500 w-full text-center py-2 rounded-b-lg">{image.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CoachPanel
