import React, { useContext } from 'react';
import { Outlet, Link } from "react-router-dom";
import { AuthContext } from '../AuthContext';
import { auth } from '../firebase'
import purple from '../assets/purple.png';

function Hero() {
  const currentUser = useContext(AuthContext);

  const handleSignOut = () => {
    auth.signOut()
      .then(() => {
        // Sign-out successful
        console.log('User signed out successfully');
      })
      .catch(error => {
        // An error occurred during sign-out
        console.error('Error signing out:', error);
      });
  };

  return (
    <div className="bg-gray-100">
      <div className="container mx-auto bg-gray-300">
        <div className="flex flex-col lg:flex-row lg:justify-between py-16">
          <div className="bg-gray-400 lg:w-1/2 p-8">
            <h1 className="mb-6 text-4xl font-bold">
              Welcome to my Hero Page
            </h1>
            <p className="mb-8 text-gray-700">
              This is a simple hero page created using React and Tailwind CSS. {currentUser ? currentUser.email : 'no user'}
            </p>
            <div className="flex space-x-4">
              <button className="px-4 py-2 text-white bg-blue-500 rounded-lg">
                <Link to="/signup">Sign up</Link>
              </button>
              <button className="px-4 py-2 text-white bg-blue-500 rounded-lg" onClick={handleSignOut}>Sign Out</button>
            </div>
          </div>
          <div className="lg:w-1/2">
            <img
              src={purple}
              alt="Hero"
              className="object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
      <Outlet />
    </div>
  );
}

export default Hero;
