import React, { useState } from 'react'

import 'firebase/compat/auth';

import { getAuth, createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from 'firebase/auth';

import { firestore } from '../firebase';

import { doc, setDoc, collectionGroup, query, where, getDocs } from "firebase/firestore"; 

import { useNavigate } from 'react-router-dom';


function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [Fname, setFname] = useState('');
    const [Lname, setLname] = useState('');
    const [profileURL, setProfileURL] = useState('');
    const [email_error, setEmailError] = useState('');
    const [pass_error, setPassError] = useState('');
    const [fname_error, setFnameError] = useState(null);
    const [lname_error, setLnameError] = useState(null);
    const [profileURL_error, setprofileURLError] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [validUserPass, setvalidUserPass] = useState(false);

    const navigate = useNavigate();

    const auth = getAuth();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    // set the value of the email state
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    }

    // set the value of the password state
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleFnameChange = (event) => {
        setFname(event.target.value);
    };
    
    const handleLnameChange = (event) => {
        setLname(event.target.value);
    };

    const handleProfileURLChange = (event) => {
        setProfileURL(event.target.value);
    };
    //  checks if the user already exists in the firebase instance 
    const checkUserExistence = async (email) => {
        try {
          const signInMethods = await fetchSignInMethodsForEmail(auth, email)
          console.log(signInMethods.length)
          console.log('called')
          return signInMethods.length > 0; // True if user exists, false otherwise
        } 
        catch (error) {
          console.error('Error checking user existence:', error);
        }
    };

    // when user loses focus on the email input, it will validate their input by setting a value to email_error state
    // const handleEmailOnBlur = async () => {

    //     const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
    //     const userExists = await checkUserExistence(email);

    //     if (!emailRegex.test(email)) {
    //         setEmailError('Please enter a valid email address.');
    //         return;
    //     }
    //     else if (email.length > 127) {
    //         setEmailError('Email must be between 3 to 128 characters.');
    //         return;
    //     }
    //     else if (!email) {
    //         setEmailError('Please enter your email address.');
    //         return;
    //     }
    //     else setEmailError(null)

    //         //     setEmailError('Email already exists!');
    //         //     console.log('Email already exists!');
    

    // };

    // when user loses focus on the password input, it will validate their input by setting a value to pass_error state
    // const handlePassOnBlur = async () => {

    //     if (password.length < 6) {
    //         setPassError('Password must be 6 characters or more.');
    //         return;
    //     }
    
    //     if (password.length > 200) {
    //         setPassError('Your password cannot exceed a maximum of 200 characters.');
    //         return;            
    //     }

    //     if (!password) {
    //         setPassError('Please enter your password.');
    //         return;
    //     }
    //     else setPassError(null)

    // }; 

     // when user loses focus on the Fname input, it will validate their input by setting a value to fname_error state
    const handleFnameOnBlur = () => {

        const FnameRegex = /^[A-Za-z]+$/
        
        if (Fname.length < 1) {
            setFnameError('Please enter your first name.');
            return;
        }
    
        if (Fname.length > 50) {
            setFnameError('First name can not exceed 50 characters.');
            return;            
        }
        
        if (!Fname || !FnameRegex.test(Fname)) {
            setFnameError('Please enter a valid first name.');
            return;
        }
        else setFnameError(null)
    };
    
    // when user loses focus on the Fname input, it will validate their input by setting a value to fname_error state
    const handleLnameOnBlur = () => {

        const LnameRegex = /^[A-Za-z]+$/
        
        if (Lname.length < 1) {
        setLnameError('Please enter your last name.');
        return;
        }
    
        if (Lname.length > 50) {
        setLnameError('Last name can not exceed 50 characters.');
        return;            
        }
        
        if (!Lname || !LnameRegex.test(Lname)) {
        setLnameError('Please enter a valid last name.');
        return;
        }

        else setLnameError(null)
    };   

    // when user loses focus on the Fname input, it will validate their input by setting a value to fname_error state
    const handleProfileURLOnBlur = () => {

        const profileURLRegex = /^[a-zA-Z0-9]+$/
        
        if (profileURL.length < 3) {
        setprofileURLError('Your custom URL must contain more than 3 characters');
        return;
        }

        if (profileURL.length > 100) {
        setprofileURLError('Your custom URL must contain less than 100 characters');
        return;
        }
    
        if (!profileURLRegex.test(profileURL)) {
        setprofileURLError('Please do not use spaces, symbols, or special characters.');
        return;            
        }
        
        if (!profileURL) {
        setprofileURLError('Please enter a valid profile URL.');
        return;
        }

        else setprofileURLError(null)
    };

    // if user presses enter on the email input, it will bring focus to the password input 
    const handleEmailKeyDown = (event) => {
        if (event.key === "Enter") {
        document.getElementById("password-input").focus();
        }
    };

    // if user presses enter on the password input, it will bring focus to the agree & join button
    const handlePassKeyDown = (event) => {
        if (event.key === "Enter") {
        document.getElementById("agree-btn").focus();
        }
    };
    
    // if user presses enter on the password input, it will bring focus to the agree & join button
    const handleFnameKeyDown = (event) => {
        if (event.key === "Enter") {
        document.getElementById("lname-input").focus();
        }
    };
    
    const handleLnameKeyDown = (event) => {
        if (event.key === "Enter") {
        document.getElementById("profileurl-input").focus();
        }
    };

    const handleProfileURLKeyDown = (event) => {
        if (event.key === "Enter") {
        document.getElementById("continue-btn").focus();
        }
    };

    const handleAgreeSubmit = async () => {
        setEmailError(null);
        setPassError(null);
        
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if (!emailRegex.test(email)) {
          setEmailError('Please enter a valid email address.');
          return;
        } else if (email.length > 127) {
          setEmailError('Email must be between 3 to 128 characters.');
          return;
        } else if (!email) {
          setEmailError('Please enter your email address.');
          return;
        }
      
        if (password.length < 6) {
          setPassError('Password must be 6 characters or more.');
          return;
        }
      
        if (password.length > 200) {
          setPassError('Your password cannot exceed a maximum of 200 characters.');
          return;
        }
      
        if (!password) {
          setPassError('Please enter your password.');
          return;
        }
      
        try {
          const userExists = await checkUserExistence(email);
      
          if (!userExists) {
            setvalidUserPass(true);
            console.log('new email');
          } else {
            // Handle case when user already exists
            setEmailError('user already exists')
        }
        } catch (error) {
          console.error('Error:', error);
          // Handle the error appropriately
        }
      };
      
    const handleSubmit = async (event) => {
        event.preventDefault();

        const nameRegex = /^[A-Za-z]+$/

        const profileURLRegex = /^[a-zA-Z0-9]+$/
        
        if (Fname.length < 1) {
            setFnameError('Please enter your first name.');
            return;
        }
    
        if (Fname.length > 50) {
            setFnameError('First name can not exceed 50 characters.');
            return;            
        }
        
        if (!Fname || !nameRegex.test(Fname)) {
            setFnameError('Please enter a valid first name.');
            return;
        }

        if (Lname.length < 1) {
            setLnameError('Please enter your last name.');
            return;
        }
    
        if (Lname.length > 50) {
            setLnameError('Last name can not exceed 50 characters.');
            return;            
        }
        
        if (!Lname || !nameRegex.test(Lname)) {
            setLnameError('Please enter a valid last name.');
            return;
        }

        if (profileURL.length < 3) {
            setprofileURLError('Your custom URL must contain more than 3 characters');
            return;
        }

        if (profileURL.length > 100) {
            setprofileURLError('Your custom URL must contain less than 100 characters');
            return;
        }
    
        if (!profileURLRegex.test(profileURL)) {
            setprofileURLError('Please do not use spaces, symbols, or special characters.');
            return;            
        }
        
        if (!profileURL) {
            setprofileURLError('Please enter a valid profile URL.');
            return;
        }


        // console.log()
        const x = query(collectionGroup(firestore, 'users'), where('profileURL', '==', profileURL))
        const y = await getDocs(x);

        if (!y.empty) {
            // y.forEach((doc) => {
            // console.log(doc.id, ' => ', doc.data());
            // });
            setprofileURLError('URL taken');
            return;
        }
        


        if (!fname_error && !lname_error && !profileURL_error){
            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // User created successfully
                const user = userCredential.user;
                console.log(user);

                setDoc(doc(firestore, "users", user.uid), {
                    firstName: Fname,
                    lastName: Lname,
                    profileURL: profileURL,
                    calendar: {}
                  });

                // You can redirect to a new page here
                navigate('/home', {replace: true})

            })
            .catch((error) => {
                // Handle errors here
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode, errorMessage);
            })
        }
        
    }

    return (
        <div className='flex h-screen bg-blue-100'>
            <div className='container px-5 py-12 m-auto bg-red-100 rounded-lg shadow-lg '>
            <form className='flex-col bg-green-200' onSubmit={handleSubmit}>
            {validUserPass ?
            <>
             <label>
                 First name
                 <br/>
                 <input
                     className='w-full p-1 rounded-sm cursor-pointer'
                     type="text"
                     value={Fname}
                     onChange={handleFnameChange}
                     onBlur={handleFnameOnBlur}
                     onKeyDown={handleFnameKeyDown}
                 />
             </label>

            {fname_error && <div className="text-red-500">{fname_error}</div>}

             <br/>
             
             <label>
             Last name
             <br/>
             <div className='grid grid-cols-10'>
                 <input
                     id="lname-input"
                     className='col-span-10 col-start-1 p-1 rounded-sm cursor-pointer row-span-full'
                     type="text"
                     value={Lname}
                     onChange={handleLnameChange}
                     onBlur={handleLnameOnBlur}
                     onKeyDown={handleLnameKeyDown}
                 />
             </div>
             </label>

             {lname_error && (<div className='text-red-500'>{lname_error}</div>)}
             
             <br/>
            <label>
            Profile URL (https://xxxxx.com/profile/YourURLHere)
             <br/>
             <div className='grid grid-cols-10'>
                 <input
                     id="profileurl-input"
                     className='col-span-10 col-start-1 p-1 rounded-sm cursor-pointer row-span-full'
                     type="text"
                     value={profileURL}
                     onChange={handleProfileURLChange}
                     onBlur={handleProfileURLOnBlur}
                     onKeyDown={handleProfileURLKeyDown}
                 />
             </div>
             </label>

             {profileURL_error && (<div className='text-red-500'>{profileURL_error}</div>)}
             
             <br/>

             <button id="continue-btn" className='content-center px-8 py-1 bg-blue-400 rounded-3xl' type="submit">Continue</button>
             </>
         :
         <>
         <label>
             Email
             <br/>
             <input
                 className='w-full p-1 cursor-pointer rounded-s'
                 type="email"
                 value={email}
                 onChange={handleEmailChange}
                //  onBlur={handleEmailOnBlur}
                 onKeyDown={handleEmailKeyDown}
             />
         </label>

         {email_error && (<div className='text-red-500'>{email_error}</div>)}

         <br />
         
         <label>
         Password (6 or more characters)
         <br/>
         <div className='grid grid-cols-10'>
             <input
                 id="password-input"
                 className='col-span-10 col-start-1 p-1 rounded-sm cursor-pointer row-span-full'
                 type={showPassword ? "text" : "password"}
                 value={password}
                 onChange={handlePasswordChange}
                //  onBlur={handlePassOnBlur}
                 onKeyDown={handlePassKeyDown}
             />
             <p
                 className="col-span-1 col-end-11 p-1 font-bold text-center text-blue-600 bg-transparent cursor-pointer select-none password-toggle-btn row-span-full bg-red-50 mr-14"
                 onClick={togglePasswordVisibility}
                 >
                 {showPassword ? "Hide" : "Show"}
             </p>
         </div>
         </label>

         {pass_error && (<div className='text-red-500'>{pass_error}</div>)}
         
         <div className='text-xs text-center'>By clicking Agree & Join, you agree to the User Agreement, Privacy Policy, and Cookie Policy.</div>
         
         <br />
         
         <button id="agree-btn" className={'bg-blue-400 rounded-3xl px-8 py-1 content-center' } onClick={handleAgreeSubmit}>Agree & Join</button>

        </>
        }
     </form>
        </div>
    </div>
    );
}

export default SignUp;