import React from 'react';
import { useLocation } from 'react-router-dom';
import Fuse from 'fuse.js';

// import { AuthContext } from '../AuthContext';
// import { auth } from '../firebase';

const Search = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const query = queryParams.get('query');

  // Assuming users is your list of users
  const users = [
    { name: 'User1' },
    { name: 'User2' },
    // ...
  ];

  const options = {
    keys: ['name'],
    includeScore: true,
    threshold: 0.3, // Adjust this as needed
  };

  const fuse = new Fuse(users, options);
  const result = fuse.search(query);

  return (
    <div>
      <p>Query: {query}</p>
      <p>Results: {result.map(user => <p>{user.item.name}</p>)}</p>
    </div>
  );
};

export default Search;