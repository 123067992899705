import React from 'react'

function TextBanner() {
  return (
    <div className="bg-green-200 flex justify-center w-full">
      <div className='container flex mx-auto'>
        <div className="bg-orange-100 w-1/2 p-8 ">
          <h1 className="text-4xl font-bold mb-4">Large Piece of Text</h1>
          <p className="text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed mattis
            urna libero, vel vehicula urna dignissim ut. Fusce tristique,
            sapien vel interdum sodales, arcu lectus tincidunt leo, quis gravida
            elit elit vel lectus. Nam feugiat orci in enim bibendum vestibulum.
          </p>
        </div>
        <div className="bg-red-100 w-1/2 flex justify-center items-center">
          <p className="text-lg">Small Piece of Text</p>
        </div>

      </div>
    </div>
  )
}

export default TextBanner