// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration

// need a secret manager for this

// const firebaseConfig = {
//     apiKey: "AIzaSyDBfalPpVCPpuVISuop_oKi_CRvK3_hCmM",
//     authDomain: "mentoring-fb.firebaseapp.com",
//     projectId: "mentoring-fb",
//     storageBucket: "mentoring-fb.appspot.com",
//     messagingSenderId: "750080154161",
//     appId: "1:750080154161:web:ac54a604b33dcf0da25310",
//     measurementId: "G-ZVRSGMLCE1"
//   };

const firebaseConfig = {
  apiKey: "AIzaSyAL_qC9uqL7WLq1ca7pHGjugGDYxhhAqAs",
  authDomain: "mentoring-c8e2e.firebaseapp.com",
  projectId: "mentoring-c8e2e",
  storageBucket: "mentoring-c8e2e.appspot.com",
  messagingSenderId: "918397178923",
  appId: "1:918397178923:web:35802eeb78a692b3e9c66e",
  measurementId: "G-56YYJH4EZH"
};
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

export const firestore = getFirestore(app);

export default app;
