 import React, { useState } from 'react'
import 'firebase/compat/auth';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

/**
 * Represents the sign-up component.
 * @returns {JSX.Element} The sign-up component JSX element.
 */
function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passError, setPassError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const auth = getAuth();

    /**
     * Toggles the visibility of the password.
     */
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    /**
     * Handles the change event for the email input field.
     * Updates the email state and logs a message to the console.
     *
     * @param {Object} event - The change event object.
     */
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        console.log('email changed')
    }

    /**
     * Handles the change event for the password input field.
     * @param {object} event - The event object.
     */
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    /**
     * Handles the keydown event for the email input field.
     * If the Enter key is pressed, it focuses on the password input field.
     * @param {Event} event - The keydown event object.
     */
    const handleEmailKeyDown = (event) => {
        if (event.key === "Enter") {
        document.getElementById("password-input").focus();
        }
    };

    /**
     * Handles the key down event for the password input field.
     * If the Enter key is pressed, it focuses on the "agree-btn" element.
     * @param {Event} event - The key down event object.
     */
    const handlePassKeyDown = (event) => {
        if (event.key === "Enter") {
        document.getElementById("agree-btn").focus();
        }
    };
    
    /**
     * Handles the submit action when the user agrees.
     */
    const handleAgreeSubmit = () => {
      
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
        
        if (!emailRegex.test(email)) {
            setPassError(null)
            setEmailError('Please enter a valid email address.');
            return
        } 
        else if (email.length > 127) {
            setPassError(null)
            setEmailError('Email must be between 3 to 128 characters.');
            return;
        } 
        else if (!email) {
            setPassError(null)
            setEmailError('Please enter your email address.');
            return;
        }
        else{
            setEmailError(null)
        } 
        

        if (password.length < 6) {
        setPassError('Password must be 6 characters or more.');
        return;
        }
    
        if (password.length > 200) {
        setPassError('Your password cannot exceed a maximum of 200 characters.');
        return;            
        }

        if (!password) {
        setPassError('Please enter your password.');
        return;
        }  else setPassError(null)

    };

    /**
     * Handles the form submission for the login functionality.
     * @param {Event} event - The form submission event.
     * @returns {Promise<void>} - A promise that resolves when the form submission is handled.
     */
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!passError && !emailError) {
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    // Signed in 
                    const user = userCredential.user;
                    console.log(user);
                    navigate('/home', {replace: true})

                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    setPassError('Incorrect password or email. Please try again.');
                    console.log('errorCode', errorCode);
                    console.log('errorMessage', errorMessage);
            });
        } else console.log('invalid pass/email')
        
    };

    return (
        <div className='flex h-screen bg-blue-100'>
            <div className='container px-5 py-12 m-auto bg-red-100 rounded-lg shadow-lg '>
            <form className='flex-col bg-green-200' onSubmit={handleSubmit}>
             <label>
                 Email
                 <br/>
                 <input
                     className='w-full p-1 rounded-sm cursor-pointer'
                     type="text"
                     value={email}
                     onChange={handleEmailChange}
                    //  onBlur={handleEmailOnBlur}
                     onKeyDown={handleEmailKeyDown}
                 />
             </label>

            {emailError && <div className="text-red-500">{emailError}</div>}

             <br/>
             
             <label>
                Password (6 or more characters)
                <br/>
                <div className='grid grid-cols-10'>
                    <input
                        id="password-input"
                        className='col-span-10 col-start-1 p-1 rounded-sm cursor-pointer row-span-full'
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={handlePasswordChange}
                        // onBlur={handlePassOnBlur}
                        onKeyDown={handlePassKeyDown}
                    />
                    <p
                        className="col-span-1 col-end-11 p-1 font-bold text-center text-blue-600 bg-transparent cursor-pointer select-none password-toggle-btn row-span-full bg-red-50 mr-14"
                        onClick={togglePasswordVisibility}
                        >
                        {showPassword ? "Hide" : "Show"}
                    </p>
                </div>
            </label>

             {passError && (<div className='text-red-500'>{passError}</div>)}
             
             <br/>

             <button id="agree-btn" className={'bg-blue-400 rounded-3xl px-8 py-1 content-center' } onClick={handleAgreeSubmit}>
                Login
            </button>
                     </form>
            </div>
        </div>
    );
}

export default SignUp