import React, { useState, useEffect, useCallback, useContext } from 'react';
import moment from 'moment';
import 'moment/locale/en-gb'; // Set the desired locale
import { doc, getDoc, getDocs, updateDoc, collectionGroup, query, where  } from "firebase/firestore"; 
import { firestore } from "../firebase";
import { v4 as uuidv4 } from 'uuid';
import { AuthContext } from '../AuthContext';

const Calendar = (props) => {

  const [selectedDate, setSelectedDate] = useState(moment())
  const [startPoint, setStartPoint] = useState(null)
  const [pressedEscape, setEscape] = useState(false)
  const [mouseDownDate, setMouseDownDate] = useState(null)
  const [showEventDiv, setShowEventDiv] = useState(false)
  const [isEventOpen, setEventOpen] = useState(false)

  const [clickedEvent, setClickedEvent] = useState({})
  const [isNewEvent, setIsNewEvent] = useState(false)

  const { timeZone } = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [pageUserData, setPageUserData] = useState({})
  const [pageUserDoc, setPageUserDoc] = useState(null);
  const [triggerUseEffect, setTriggerUseEffect] = useState(false);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [sessionType, setSessionType] = useState('networking');

  // eslint-disable-next-line
  const { currentUser, userData, userDoc, loadedState } = useContext(AuthContext);

  const getPageUserData = async () => {
    console.log('firebase accessed')
    const pageUserDocQuery = await getDocs(query(collectionGroup(firestore, 'users'), where('profileURL', '==', props.profileURL)))
  
    setPageUserDoc(doc(firestore, 'users', pageUserDocQuery.docs[0].ref.path.split('/')[1]))
    const docSnap = (await getDoc(doc(firestore, 'users', pageUserDocQuery.docs[0].ref.path.split('/')[1])));
  
    return docSnap.data() || []
  };
  
  // // Fetch the most recent version of the user's calendar whenever an element on the page changes.
  useEffect(() => {
    const fetchData = async () => {
      try {

  //       // Get data for currently logged in user
  //       setUserData(await getUserData());

        // Get data for user of the page
        setPageUserData(await getPageUserData());
        
      } catch (error) {
        console.error('Error fetching user calendar:', error);
      }
    }

    fetchData();
   // should re-render ONLY when final user or page user data changes
   // eslint-disable-next-line 
  }, [triggerUseEffect]);


  // On click 'Escape Key' we will cancel the creation of the event when the user is in the process of making one. For example if they have their mouse pressed down, but want to cancel, they can use escape. 
  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      // console.log('escape key pressed');

      setTitle('');
      setDescription('');
      setEventOpen(false);
      setEscape(true);
    }
  }, []);

  // This adds a listener for the 'Escape Key' on the current document.
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

async function removeEventFromCalendar(event) {

  setShowEventDiv((showEventDiv) => !showEventDiv);
  
  const updatedUserCalendar = { ...userDoc.calendar };
  delete updatedUserCalendar[event.event_id];
  await updateDoc(userDoc, {
    calendar: updatedUserCalendar
  });

  const updatedPageUserCalendar = { ...pageUserDoc.calendar };
  delete updatedPageUserCalendar[event.event_id];
  await updateDoc(pageUserDoc, {
    calendar: updatedPageUserCalendar
  });
  
  setTriggerUseEffect(!triggerUseEffect)

}
  

  function convertTimeToValue(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    return (hours * 60) + minutes;
  }
  
  async function editEventFromCalendar(event, newStartpx, newEndpx) {

    if (isValidObject(event.event_id, convertTimeToValue(newStartpx),convertTimeToValue(newEndpx), event.date) && (
      (convertTimeToValue(newEndpx) === event.endpx && convertTimeToValue(newStartpx) !== event.startpx) ||
      (convertTimeToValue(newStartpx) === event.startpx && convertTimeToValue(newEndpx) !== event.endpx)
    )) {
      

      const updatedUserCalendar = { ...userDoc.calendar };
      delete updatedUserCalendar[event.event_id];
      await updateDoc(userDoc, {
        calendar: updatedUserCalendar
      });

      const updatedPageUserCalendar = { ...pageUserDoc.calendar };
      delete updatedPageUserCalendar[event.event_id];
      await updateDoc(pageUserDoc, {
        calendar: updatedPageUserCalendar
      });

      event.startpx = convertTimeToValue(newStartpx)
      event.endpx = convertTimeToValue(newEndpx)
      
      console.log(event)

      await updateDoc(userDoc, {
        calendar: {
          ...userDoc.calendar,
          [event.event_id]: event
        }
      });
      
      await updateDoc(pageUserDoc, {
        calendar: {
          ...pageUserDoc.calendar,
          [event.event_id]: event
        }
      });

    }
    else {
      console.log('new event is bad', event)
    }
  }

  async function addEventFirebase() {
    setShowEventDiv((showEventDiv) => !showEventDiv);

    clickedEvent.title = title;
    clickedEvent.description = description;
    clickedEvent.session_type = sessionType;
    const new_event_id = uuidv4()
    updateDoc(userDoc, { calendar: { ...userDoc.calendar, [new_event_id]: clickedEvent } })
    updateDoc(pageUserDoc, { calendar: { ...pageUserDoc.calendar, [new_event_id]: clickedEvent } })

    setTriggerUseEffect(!triggerUseEffect)

    setTitle('');
    setDescription('');
  }

  
  // Go to the previous week for the calendar
  const handlePrevWeek = () => {
    setSelectedDate((prevDate) => prevDate.clone().subtract(1, 'week'));
  };

  // Go to the next week for the calendar
  const handleNextWeek = () => {
    setSelectedDate((prevDate) => prevDate.clone().add(1, 'week'));
  };

  // Go to the previous month for the calendar
  const handlePrevMonth = () => {
    setSelectedDate((prevDate) => prevDate.clone().subtract(1, 'month'));
  };

  // Go to the next month for the calendar
  const handleNextMonth = () => {
    setSelectedDate((prevDate) => prevDate.clone().add(1, 'month'));
  };

  // On click of dark grey overlay, it will cancel the event operation
  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('eventOverlay')) {
      setEventOpen(false);
      setTitle('');
      setDescription('');
      resetErrors(); 
    }
  };
  
  // Render the UI Header
  const renderHeader = () => {
    const dateFormat = 'MMMM YYYY';
    return (
      <div className="flex justify-between items-center">
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={handlePrevMonth}
        >
          Previous Month
        </button>
        <h2 className="text-xl font-bold">{selectedDate.format(dateFormat)}</h2>
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={handleNextMonth}
        >
          Next Month
        </button>
      </div>
    );
  };

  // Render the UI Days
  const renderDays = () => {
    const weekdays = moment.weekdaysShort();
    const dateFormat = 'D';

    return (
      <div className="flex">
        <div className="ml-8 w-1/7"></div>
        {weekdays.map((day) => (
          <div key={day} className="w-1/6 text-center font-bold">
            <div>{day}</div>
            <div className="mt-1">{selectedDate.clone().day(day).format(dateFormat)}</div>
          </div>
        ))}
      </div>
    );
  };

  // Render the UI Columns
  const renderTimeColumn = () => {
    const times = [];
    const startTime = moment().startOf('day');
    const endTime = moment().endOf('day');

    while (startTime.isBefore(endTime)) {
      times.push(startTime.format('LT'));
      startTime.add(1, 'hour');
    }

    return (
      <div className="flex flex-col">
        {times.map((time) => (
          <div key={time} className="h-16 flex items-center justify-end pr-2">
            {time}
          </div>
        ))}
      </div>
    );
  };

  const resetErrors = () => {
    setTitleError('');
    setDescriptionError('');
  };

  // Function to check if the object is value -- Will need to add logic for user and page user's calendar
  const isValidObject = (eventid, start, end, date) => {

    const combinedSet = new Set([
      ...(Object.values(userData['calendar'] || {})),
      ...(Object.values(pageUserData['calendar'] || {}))
    ]);

    const combinedCalendar = Array.from(combinedSet)

    for (let i = 0; i < combinedCalendar.length; i++) {
      const existingObject = combinedCalendar[i];
  
      if (existingObject.date === date && existingObject.event_id !== eventid) {
        if (
          (start < existingObject.endpx && end > existingObject.startpx) ||
          (start === existingObject.endpx) ||
          (end === existingObject.startpx)
        ) {

          return false; 
        }
      }
    }
    return true;
  };
  
  const convertPixelsToTime = (pixels) => {
    return String(Math.floor(pixels/60)).padStart('2',0) + ':' + String(pixels % 60).padStart('2', 0)
  }

  // Function to render events in the cell plus the logic for the columns, plus other logic
  const renderCells = () => {
    console.log(timeZone);

    const combinedSet = new Set([
      ...(Object.values(userData['calendar'] || {})),
      ...(Object.values(pageUserData['calendar'] || {}))
    ]);
    // Check if any event_id values are duplicated
    const eventIds = new Set();
    const combinedCalendar = Array.from(combinedSet).filter(event => {
      if (eventIds.has(event.event_id)) {
        return false; // Skip event if event_id is duplicated
      }
      eventIds.add(event.event_id);
      return true;
    });
    
    console.log(combinedCalendar, 'combinedCalendar');


    const startOfWeek = selectedDate.clone().startOf('day'); // Set time to start of the day
    const endOfWeek = selectedDate.clone().endOf('week').day(6); // Set end day to Saturday
    const calendarColumns = [];

    let currentDate = startOfWeek.clone();
    // console.log('startOfWeek.clone()',startOfWeek.clone())

    while (currentDate.isSameOrBefore(endOfWeek, 'day')) {
      const cellKey = currentDate.format('MM-DD-YYYY');
      const cellEvents = (combinedCalendar || []).filter((event) => event && event.date === cellKey);

      const handleMouseDown = (event, date) => {
        const isCellEvent = event.target.classList.contains('cell-event');

        if (!isCellEvent) {
          setStartPoint(event.nativeEvent.offsetY);
          setMouseDownDate(date);
        }
        };

      async function createEvent(event, startpoint) {
        
        if (pressedEscape) {
          setEscape(false)
          return
        }

        setIsNewEvent(true)

        const endPoint = event.nativeEvent.offsetY;
        const new_event_id = uuidv4()
        console.log('eventtes3', combinedCalendar)
        // Check if an event with the same event ID already exists
        const eventWithSameId = (combinedCalendar || []).find((event) => event.event_id === new_event_id);

        // console.log('create even func tried')

        if (
          Math.abs(startpoint - endPoint) > 15 &&
          isValidObject(new_event_id, Math.min(startpoint, endPoint), Math.max(startpoint, endPoint), mouseDownDate) &&
          startPoint !== null &&
          !eventWithSameId
        ) 
        {

          // Original format: "MM-DD-YYYY"
          console.log(mouseDownDate, 'mouseDownDate');
          const [month, day, year] = mouseDownDate.split('-');

          // Convert to ISO 8601 format: "YYYY-MM-DD"
          const isoFormattedDate = `${year}-${month}-${day}`;

          const start_time = new Date(`${isoFormattedDate}T00:00:00`);
          if (isNaN(start_time.getTime())) {
            throw new Error('start_time is an invalid date');
          }

          start_time.setMinutes(startpoint);
          if (isNaN(start_time.getTime())) {
            throw new Error('Invalid start_time after setting minutes');
          }

          const end_time = new Date(`${isoFormattedDate}T00:00:00`);
          end_time.setMinutes(endPoint);
          if (isNaN(end_time.getTime())) {
            throw new Error('Invalid end_time after setting minutes');
          }
          
          // title and description will be added in the addEventFirebase function
          const clickedEvent = {
            date: mouseDownDate,
            host: pageUserDoc.id,
            member: currentUser.uid,
            startpx: Math.min(startpoint, endPoint),
            endpx: Math.max(startpoint, endPoint),
            start_time: start_time,
            end_time: end_time,

          };
          
          setClickedEvent(clickedEvent);
          setShowEventDiv(true); 
          setEventOpen(true)

        }

        setStartPoint(null);
      };

      calendarColumns.push(
        <div
          id="calcol"
          key={cellKey}
          className="flex flex-col relative w-10 bg-green-200 mr-4 active"
          onMouseDown={(event) => handleMouseDown(event, cellKey)}
          onMouseUp={(event) => createEvent(event, startPoint)}
        >
          {cellEvents.map((event, index) => {
            
            if (!event) {
              return null; // Skip rendering if the event is undefined
            }
    
            function checkOverlap(object) {
            // Iterate through the previously rendered events to find overlaps
            cellEvents.forEach((otherEvent, otherIndex) => {
              if (otherIndex !== index) {
                if (
                  (object.startpx >= otherEvent.startpx && object.startpx <= otherEvent.endpx) ||  // Check if event starts within otherEvent
                  (object.endpx >= otherEvent.startpx && object.endpx <= otherEvent.endpx) ||      // Check if event ends within otherEvent
                  (object.startpx <= otherEvent.startpx && object.endpx >= otherEvent.endpx)       // Check if event completely contains otherEvent
                ) {
                  return true
                }
                return false
              }
            });
            }

            const parentElement = document.getElementById('calcol');
            const parentHeight = parentElement ? parentElement.clientHeight : 0;
            
            const topStyle = `${event.startpx}px`;
            const bottomStyle = `${parentHeight - event.endpx}px`;
            
            // const [month, day, year] = cellKey.split('-');

            // const start_time = new Date(year,month - 1, day, Math.floor(`${event.startpx}`/60), `${event.startpx}` % 60);
            // const start_time_hours = start_time.getHours()
            // const start_time_minutes = start_time.getMinutes()
            // const start_time_formatted = start_time_hours.toString().padStart(2,"0") + ':' + start_time_minutes.toString().padStart(2,"0")
            
            // const end_time = new Date(year,month - 1, day, Math.floor((`${event.endpx}`)/60),`${event.endpx}` % 60)
            // const end_time_hours = end_time.getHours()
            // const end_time_minutes = end_time.getMinutes()
            // const end_time_formatted = end_time_hours.toString().padStart(2,"0") + ':' + end_time_minutes.toString().padStart(2,"0")
            
            function handleClickEvent() {
              // if (!clickedEvent) {
              //   console.error('Event is undefined.');
              //   return;
              // }
              // const docSnap = (await getDoc(userDoc)).data();
              // console.log('Clicked event ID:', event.event_id);
              // console.log('event got:', docSnap['calendar']);

              
              const foundEvent = (combinedCalendar || []).find(calendarEvent => calendarEvent.event_id === event.event_id)

              console.log('eventstartpx on click',foundEvent.startpx)
              console.log('eventendpx on click',foundEvent.endpx)
              // console.log('event got:', foundEvent);

              setShowEventDiv(true)
              setEventOpen(true)
              setIsNewEvent(false)

              setClickedEvent(foundEvent)
            }

            return (
                <div
                  key={index}
                  className="cell-event absolute bg-red-200 cursor-pointer opacity-70	"
                  style={{
                    top: topStyle,
                    bottom: bottomStyle,
                    backgroundColor: ((event.host === pageUserDoc?.id) && (event.member === currentUser.uid)) ? 'red' : ((event.host === pageUserDoc?.id) && (event.member !==  currentUser.uid)) ? 'green' : checkOverlap(event) ? 'yellow' : 'blue',
                  }}
                  onClick={() => {
                    if (event.host === pageUserDoc?.id || event.member === currentUser.uid) {
                      handleClickEvent();
                    }
                  }}
                >

                {/* test */}
                {/* (event)  */}
                {/* <br/> */}
                {event.host}
                <br/>
                {pageUserDoc?.id}
                <br/>
                {event.member}
                <br/>
                {currentUser.uid}
                <br/>
                {/* {getUserData('profileURL')} */}
                {/* {start_time_formatted}-{end_time_formatted} Replace with the appropriate property that represents the event */}
              </div>
            );
          })}
        </div>
      );

      currentDate.add(1, 'day');
    }

    return calendarColumns;
  };

  return (
    <div className="container mx-auto p-4 z-30">
      <div className="mb-4">
        <button
          className="text-gray-500 hover:text-gray-700"
          onClick={handlePrevWeek}
        >
          Previous Week
        </button>
        <button
          className="text-gray-500 hover:text-gray-700 ml-4"
          onClick={handleNextWeek}
        >
          Next Week
        </button>
      </div>
      <div className="mb-4 ">{renderHeader()}</div>
      <div className="bg-red-100 mb-4 ">{renderDays()}</div>
      <div className="flex h-[1440px]  overflow-scroll">
        <div className="bg-yellow-500 w-1/7 h-[1440px]">
          {renderTimeColumn()}
        </div>
        <div className="flex flex-grow bg-blue-500 h-[1440px]">
          
          {renderCells()}

        </div>
      </div>
      
      {/* Logic to show the add event box */}
    {(showEventDiv && isEventOpen && isNewEvent) && (
      <div onClick={handleOverlayClick} className="eventOverlay fixed top-0 left-0 w-full h-full flex items-center justify-center z-20">
        <div className="absolute top-1/2 left-1/2 h-50 w-50 bg-white border border-gray-300">
          
        <input 
          type='text' 
          placeholder='Title'
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
            if (e.target.value.trim() === '') {
              setTitleError('Title is required');
            } else {
              setTitleError('');
            }
          }}
        />

        {titleError && <div className="text-red-500">{titleError}</div>}

          <br/>
          <p>Date: {clickedEvent.date}</p>
          <br/>
          <input 
            type='text' 
            placeholder='Description'
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              if (e.target.value.trim() === '') {
                setDescriptionError('Description is required');
              } else {
                setDescriptionError('');
              }
            }}
          />
          {descriptionError && <div className="text-red-500">{descriptionError}</div>}
          <br/>

          <select defaultValue={sessionType} onChange={(event) => setSessionType(event.target.value)}>
            <option value="networking">Networking</option>
            <option value="seminar">Seminar</option>
           </select>

           {/* <h1>Session Type: {sessionType}</h1> */}
        <br/>

          <button 
            className='bg-red-200' 
            onClick={() => {
              if (title.trim() !== '' && description.trim() !== '') {
                addEventFirebase();
              } else {
                if (title.trim() === '') {
                  setTitleError('Title is required');
                }
                if (description.trim() === '') {
                  setDescriptionError('Description is required');
                }
              }
            }}
          >Add Event</button> 

        </div>
      </div>
      )}

      {(showEventDiv && isEventOpen && !isNewEvent) && (
      <div onClick={handleOverlayClick} className="eventOverlay fixed top-0 left-0 w-full h-full flex items-center justify-center z-20">
      <div className="absolute top-1/2 left-1/2 h-50 w-50 bg-white border border-gray-300">
        {/* Content of the event div */}
        <input type='text' placeholder='Title' required/>
        <br/>
        {/* list={() => timeDropDown(clickedEvent)} */}
        <input id="startTimeInput" className='cursor-pointer'  type='time' defaultValue={convertPixelsToTime(clickedEvent.startpx)}/>
        <br/>
        <input id="endTimeInput" className='cursor-pointer' type='time' defaultValue={convertPixelsToTime(clickedEvent.endpx)}/>
        <br/>
        <p>Date: {clickedEvent.date}</p>
        <p>HOST: {clickedEvent.host}</p>
        <p>MEMBER: {clickedEvent.member}</p>
        <br/>
        <input type='text' placeholder='Description'/>

        <br/>
          <button  className='bg-red-400' onClick={() => editEventFromCalendar(clickedEvent, document.getElementById('startTimeInput').value, document.getElementById('endTimeInput').value)}>edit event</button>
        <br/>
        <button className='bg-red-200' onClick={() => removeEventFromCalendar(clickedEvent)}>delete event</button>
      </div>
    </div>
    )}
      
    </div>
  );
};

export default Calendar;
