import React, { createContext, useEffect, useState } from 'react';
import { auth, firestore } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [userDoc, setUserDoc] = useState(null);
  const [loadedState, setLoadedState] = useState(false);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        setUserDoc(userDocRef);
        const unsubscribeSnapshot = onSnapshot(userDocRef, (doc) => {
          setUserData(doc.exists() ? doc.data() : null);
          // Ensure loadedState is set after user data is fetched
          setLoadedState(true);
        }, (error) => {
          console.error("Error fetching user document:", error);
          setUserData(null);
          setLoadedState(true);
        });

        return unsubscribeSnapshot;
      } else {
        // User is signed out
        setUserData(null);
        setLoadedState(true); // Ensure loadedState is set when no user is signed in
      }
    });

    return () => unsubscribeAuth();
  }, []);

  const contextValue = {
    currentUser,
    userData,
    userDoc,
    loadedState,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};
