import React, { useState, useEffect, useContext, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import ZoomVideo from '@zoom/videosdk'
import { doc, getDoc, collection, setDoc, updateDoc, arrayUnion } from 'firebase/firestore'
import { firestore } from "../firebase"
import { AuthContext } from '../AuthContext'

import microphoneEnabledWhiteSVG from '../assets/microphone-enabled-white.svg'
import microphoneDisabledWhiteSVG from '../assets/microphone-disabled-white.svg'
import videoEnabledWhiteSVG from '../assets/video-enabled-white.svg'
import videoDisabledWhiteSVG from '../assets/video-disabled-white.svg'
import sampleMP3 from '../assets/sample.mp3'
import screenShareWhiteSVG from '../assets/share-white.svg'

// if the videosrc is null, and previewVideoEnabledState is true, then don't allow the user to hit the video button yet
// ln 315 issue
const VideoRefined = () => {
  
  const [previewVideoTrackState, setPreviewVideoTrackState] = useState(null)
  const [previewVideoDevicesState, setPreviewVideoDevicesState] = useState([])

  const [previewAudioInputTrackState, setPreviewAudioInputTrackState] = useState(null)
  const [previewAudioInputDevicesState, setPreviewAudioInputDevicesState] = useState([])

  const [previewAudioOutputTrackState, setPreviewAudioOutputTrackState] = useState(null)
  const [previewAudioOutputDevicesState, setPreviewAudioOutputDevicesState] = useState([])

  const [previewVideoEnabledState, setPreviewVideoEnabledState] = useState(false)
  const [previewMicrophoneEnabledState, setPreviewMicrophoneEnabledState] = useState(false)
  // eslint-disable-next-line
  const [userDevicesPermissionGrantState, setUserDevicesPermissionGrantState] = useState(null)
  const [previewPageEnabled, setPreviewPageEnabled] = useState(true)
  // eslint-disable-next-line
  const [volumeLevel, setVolumeLevel] = useState(0)
  const [stream, setStream] = useState(null)
  
  const [roomChatClient, setRoomChatClient] = useState(null)
  const [roomScreenSharedEnabledState, setRoomScreenSharedEnabledState] = useState(false)

  const [dropdownVisible, setDropdownVisible] = useState(false)


  const previewVideoTrackRef = useRef()
  const previewAudioInputTrackRef = useRef()
  const previewAudioOutputTrackRef = useRef()
  const previewVideoDevicesRef = useRef([])
  const previewAudioInputDevicesRef = useRef([])
  const previewAudioOutputDevicesRef = useRef([])
  const streamRef = useRef(stream)

  const dropdownRef = useRef(null)

  useEffect(() => {
    previewVideoTrackRef.current = previewVideoTrackState
  }, [previewVideoTrackState])

  useEffect(() => {
    previewAudioInputTrackRef.current = previewAudioInputTrackState
  }, [previewAudioInputTrackState])

  useEffect(() => {
    previewAudioOutputTrackRef.current = previewAudioOutputTrackState
  }, [previewAudioOutputTrackState])
  
  useEffect(() => {
    previewVideoDevicesRef.current = previewVideoDevicesState
  }, [previewVideoDevicesState])

  useEffect(() => {
    previewAudioInputDevicesRef.current = previewAudioInputDevicesState
  }, [previewAudioInputDevicesState])

  useEffect(() => {
    previewAudioOutputDevicesRef.current = previewAudioOutputDevicesState
  }, [previewAudioOutputDevicesState])

  useEffect(() => {
    streamRef.current = stream
  }, [stream])

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false)
      }
    }
  
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [dropdownRef])

  let volumeInterval = null
  let speakerTester = null

  const { currentUser, userData, loadedState } = useContext(AuthContext)
  const { event_id } = useParams()
  const navigate = useNavigate()
  const client = ZoomVideo.createClient()

  let audioDecode 
  let audioEncode

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////// VARIABLE SETUP //////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  client.on('media-sdk-change', (payload) => {
    if (payload.type === 'audio' && payload.result === 'success') {
      if (payload.action === 'encode') {
        // encode for sending audio stream (speak)
        audioEncode = true
      } else if (payload.action === 'decode') {
        // decode for receiving audio stream (hear)
        audioDecode = true
      }
    }
  })

  useEffect(() => {
    if (loadedState) {
     
      if (!currentUser) {
        navigate('/login?event_id=' + event_id) // Adjust this path as necessary.

      } else if (currentUser && !userData?.calendar[event_id]) {
        // alert("Calendar event not found")
        navigate('/')
      } else {
        console.log("Hello World")
      }if (currentUser && userData?.calendar[event_id]?.start_time < Date.now()) {
        console.log("Hello World1")
      } else if (currentUser && userData?.calendar[event_id]?.end_time < Date.now()) {
        console.log("Hello World2")
      }

    }
  }, [currentUser, loadedState, event_id, navigate, userData?.calendar])

  useEffect(() => { 

    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then((stream) => {
        console.log('Permission granted')
        stream.getTracks().forEach(function(track) {
          track.stop()
        })
        setUserDevicesPermissionGrantState(true)
      })
      .catch((error) => { 
        if (error.name === 'NotAllowedError') {
          console.error('Permission denied')
          setUserDevicesPermissionGrantState(false)
        } else {
          console.error('Error getting devices:', error)
        }
    })

    navigator.mediaDevices.enumerateDevices().then((devices) => {

      const videoinputs =  devices.filter(device => device.kind === 'videoinput').reverse()
      const audioinputs = modifyDeviceList(devices.filter(device => device.kind === 'audioinput'), 'audioinput')
      const audiooutputs = modifyDeviceList(devices.filter(device => device.kind === 'audiooutput'), 'audiooutput')

      console.log('videoinputs', videoinputs)

      setPreviewVideoTrackState(ZoomVideo.createLocalVideoTrack(videoinputs[0]?.deviceId))
      setPreviewVideoDevicesState(videoinputs)

      setPreviewAudioInputTrackState(ZoomVideo.createLocalAudioTrack(audioinputs[0]?.deviceId))
      setPreviewAudioInputDevicesState(audioinputs)

      setPreviewAudioOutputTrackState(audiooutputs[0])
      setPreviewAudioOutputDevicesState(audiooutputs)

      navigator.mediaDevices.ondevicechange = ((event) => {
        navigator.mediaDevices.enumerateDevices().then((devices) => {
          const videoinputs =  devices.filter(device => device.kind === 'videoinput')
          const audioinputs = modifyDeviceList(devices.filter(device => device.kind === 'audioinput'), 'audioinput')
          const audiooutputs = modifyDeviceList(devices.filter(device => device.kind === 'audiooutput'), 'audiooutput')

          if (previewVideoDevicesRef.current !== videoinputs) {

            if (!videoinputs?.map(device => device.deviceId).includes(previewVideoTrackRef?.current?.deviceId)) {
              // console.log('video-' + currentUser?.uid)
              if (streamRef.current) {
                streamRef.current.stopVideo().then(() => {}).catch((error) => {
                })
              } else {
                document.getElementById('preview-video').srcObject = null
              }
                setPreviewVideoTrackState(ZoomVideo.createLocalVideoTrack(videoinputs[0]?.deviceId))  
                setPreviewVideoEnabledState(false)
            }
            setPreviewVideoDevicesState(videoinputs)
          }

          if (previewAudioInputDevicesRef.current !== audioinputs) {
            const newDefaultAudioInput = devices.filter(device => device.label.startsWith('Default - ') && device.kind === 'audioinput')[0].label.substring('Default - '.length)
            const selectedLabel = previewAudioInputDevicesRef?.current.find(device => device.deviceId === previewAudioInputTrackRef?.current?.deviceId)?.label
            if(newDefaultAudioInput !== previewAudioInputDevicesRef?.current[0]?.label && selectedLabel === previewAudioInputDevicesRef?.current[0]?.label) {
              setPreviewAudioInputTrackState(ZoomVideo.createLocalAudioTrack(audioinputs[0]?.deviceId))
            }
            setPreviewAudioInputDevicesState(audioinputs)
          }

          if (previewAudioOutputDevicesState !== audiooutputs) {
            setPreviewAudioOutputDevicesState(audiooutputs)
          }
        })
      })

      }
    )

    .catch((error) => { 
      if (error.name === 'NotAllowedError') {
        console.error('Permission denied')
        setUserDevicesPermissionGrantState(false)
      } else {
        console.error('Error getting devices:', error)
      }
    })
// eslint-disable-next-line
  }, [])

  useEffect(() => {
    updateVideoSelectElement();
     // eslint-disable-next-line
  }, [previewVideoDevicesState])
  
  useEffect(() => {
    updateAudioInputSelectElement()
     // eslint-disable-next-line
  }, [previewAudioInputDevicesState])

  useEffect(() => {
    updateAudioOutputSelectElement()
     // eslint-disable-next-line
  }, [previewAudioOutputDevicesState])

  useEffect (() => {
    if (previewVideoEnabledState) {

      if (stream) {
        console.log('stream22222', stream) 
        if (!stream.isCapturingVideo()) {
          console.log('stream.isCapturingVideo()', stream.isCapturingVideo())
          stream.startVideo({ cameraId: previewVideoTrackState.deviceId, videoElement: document.getElementById('video-' + currentUser.uid) })
          .then(() => {
              console.log('Room Video started')
          })
          .catch((error) => {
              console.log(error)
              stream.stopVideo().then(() => {
                  console.log('Video stopped')
              }).catch((error) => {
                  console.log(error)
              })
          })
        }
      else if (stream.isCapturingVideo()) {
        console.log('stream.isCapturingVideo()', stream.isCapturingVideo())
        
        stream.stopVideo().then(() => {
          stream.startVideo({ cameraId: previewVideoTrackState.deviceId, videoElement: document.getElementById('video-' + currentUser.uid) })
          .then(() => {
              console.log('Room Video started')
          })
          .catch((error) => {
              console.log(error)
              stream.stopVideo().then(() => {
                  console.log('Video stopped')
              }).catch((error) => {
                  console.log(error)
              })
          })}
        )
        .catch((error) => {})

       
      }
      } else if (!previewVideoTrackState.isVideoStarted) {
        previewVideoTrackState.start(document.getElementById('preview-video'))
      }
  } else if (!previewVideoEnabledState){
      if (previewVideoTrackState && previewVideoTrackState.isVideoStarted) {
          
      }
  
      if (stream) {
        if (stream.isCapturingVideo()) {
          stream.stopVideo().then(() => {
            console.log('Video stopped')
          }).catch((error) => {
              console.log(error)
          })
        }
      }  else if (previewVideoTrackState?.isVideoStarted) {
        previewVideoTrackState.stop()
        .catch((error) => {
            console.error('Error starting video track:', error)
        })
        document.getElementById('preview-video').srcObject = null
      }
  }
  }, [previewVideoTrackState, previewVideoEnabledState, stream, currentUser?.uid])

  useEffect(() => {

    if (volumeInterval) {
      clearInterval(volumeInterval)
      document.getElementById("mic-input-level").reset()
    }

    console.log('previewMicrophoneEnabledState', previewMicrophoneEnabledState)
    console.log('stream', stream)
    
    if (previewMicrophoneEnabledState) {

      if (stream) {

        

        if (stream.isAudioMuted) {
          stream.unmuteAudio()
        }
      } else if (previewAudioInputTrackState && !previewAudioInputTrackState?.isAudioStarted) {
        console.log('starting local audio input', previewAudioInputTrackState)
        previewAudioInputTrackState?.start()
          .then(() => {
            if (!previewAudioInputTrackState?.isMicUnmuted) {
              previewAudioInputTrackState?.unmute()
            }
          })
          // eslint-disable-next-line
          volumeInterval = setInterval(() => setVolumeLevel(previewAudioInputTrackState?.getCurrentVolume() * 1000), 50)
      }

      if (previewAudioInputTrackState?.isAudioStarted && !previewAudioInputTrackState?.isMicUnmuted) {
        if (!previewAudioInputTrackState?.isMicUnmuted) {
          previewAudioInputTrackState?.unmute()
          console.log('unmuting local audio input', previewAudioInputTrackState)
        }
      }

    } else if (!previewMicrophoneEnabledState) {

      if (stream) {
        // if (!stream.isAudioMuted) {
        stream.muteAudio()
        // }
      } else if (previewAudioInputTrackState?.isAudioStarted && !previewMicrophoneEnabledState && previewAudioInputTrackState?.isMicUnmuted) {
        previewAudioInputTrackState?.mute()
        setVolumeLevel(0)
      }
    }

  }, [previewAudioInputTrackState, previewMicrophoneEnabledState, stream])

  useEffect(() => {

    if(roomScreenSharedEnabledState) {
      if (stream.isStartShareScreenWithVideoElement()) {
        stream
          .startShareScreen(document.getElementById('my-screen-share-content-video'))
          .then(() => {
            // screen share successfully started and rendered
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        stream
          .startShareScreen(document.getElementById('my-screen-share-content-canvas'))
          .then(() => {
            // screen share successfully started and rendered
          })
          .catch((error) => {
            console.log(error)
          })
      }
    } else if (!roomScreenSharedEnabledState && stream) {
      stream.stopShareScreen().then(() => {
        const canvas = document.getElementById('my-screen-share-content-canvas')
        canvas.srcObject = null
        const video = document.getElementById('my-screen-share-content-video')
        video.srcObject = null
        console.log('Screen share stopped')
      }).catch((error) => {
        console.log(error)
      })
  
    }
  
  
  }, [roomScreenSharedEnabledState, stream])

  const modifyDeviceList = (devices, deviceType) => {
    let deviceList = devices.filter(device => device.kind === deviceType)
    const defaultLabel = "Default - "

    const firstDevice = devices.find(device => 
      device.label.startsWith(defaultLabel)
      && devices.some(d => d.label === device.label.substring(defaultLabel.length))
      && device.kind === deviceType
    )

    if (firstDevice) {
      const matchingDeviceLabel = firstDevice.label.substring(defaultLabel.length)
      const matchingDevice = deviceList.find(device => device.label === matchingDeviceLabel)
      if (matchingDevice) {
        deviceList.splice(deviceList.indexOf(matchingDevice), 1)
        deviceList.unshift(matchingDevice)
      }
    }

    deviceList = deviceList.filter(device => device.deviceId !== 'default')

    return deviceList
  }

  const togglePreviewAudioButton = () => {
    setPreviewMicrophoneEnabledState(!previewMicrophoneEnabledState)
  }

  const togglePreviewVideoButton = () => {
    setPreviewVideoEnabledState(!previewVideoEnabledState)
  }

  const toggleScreenShareButton = () => {
    setRoomScreenSharedEnabledState(!roomScreenSharedEnabledState)

  }

  const switchVideo = (deviceName) => {
    const selectedDevice = previewVideoDevicesState.find(device => device.label === deviceName)
    if (selectedDevice) {
      console.log('switching video device to ', selectedDevice.label)
      setPreviewVideoTrackState(ZoomVideo.createLocalVideoTrack(selectedDevice.deviceId))
    }

  }

  const switchMicrophone = (deviceName) => {
    const selectedDevice = previewAudioInputDevicesState.find(device => device.label === deviceName)

    if (selectedDevice) {
      console.log('switching input device to ', selectedDevice.label, selectedDevice.deviceId)
      setPreviewAudioInputTrackState(ZoomVideo.createLocalAudioTrack(selectedDevice.deviceId))

      if (stream) {
        stream.switchMicrophone(selectedDevice.deviceId)
        .then(() => {
          console.log('Microphone switched')
          if (previewMicrophoneEnabledState) {
            stream.unmuteAudio()
          } else {
            stream.muteAudio()
          }
        })
      }
  
    }


  }

  const switchSpeaker = (deviceName) => {
    if (speakerTester) {
          speakerTester.destroy()
          speakerTester = undefined
    }
    setPreviewAudioOutputTrackState(previewAudioOutputDevicesState.find(device => device.label === deviceName))
  }
  
  const testSpeaker = () => {
    const outputLevelElm = document.getElementById("preview-speaker-output-level")
    if (previewAudioOutputTrackState && !speakerTester) {
      
      speakerTester = previewAudioInputTrackState?.testSpeaker({
        speakerId: previewAudioOutputTrackState?.deviceId,
        sampleAudioUrl: sampleMP3,
        onAnalyseFrequency: (v) => {
          console.log(v)
          outputLevelElm.value = v
        },
      })

      const audioDurationMs = 3250 // Duration in milliseconds

      setTimeout(() => {
        if (speakerTester) {
          speakerTester.destroy()
          speakerTester = null
          outputLevelElm.value = 0
          console.log('Speaker tester destroyed after one loop.')
        }
      }, audioDurationMs)
    }
  }

  const updateVideoSelectElement = () => {
    const videoSelectElement = document.getElementById('video-select')

    while (videoSelectElement.firstChild) {
      videoSelectElement.removeChild(videoSelectElement.firstChild)
    }

    console.log('previewVideoDevicesState', previewVideoDevicesState)
    
    if (previewVideoDevicesState.length > 0) {
      const selectedLabel = previewVideoDevicesState.find(device => device.deviceId === previewVideoTrackState.deviceId)?.label

        previewVideoDevicesState.forEach((device) => {
          const optionElement = document.createElement('option')
          optionElement.textContent = device.label
          videoSelectElement.appendChild(optionElement)
          if (device.label === selectedLabel) {
            optionElement.selected = true
          }
        })
    } else {
      const optionElement = document.createElement('option')
      optionElement.textContent = "Video not found"
      videoSelectElement.appendChild(optionElement)
      console.log('Video not found')
    }
  }

  const updateAudioInputSelectElement = () => {
    const audioInputSelectElement = document.getElementById('microphone-select')

    if (previewAudioInputDevicesState.length > 0) {
      const selectedLabel = previewAudioInputDevicesState.find(device => device.deviceId === previewAudioInputTrackState.deviceId)?.label
      
      if (audioInputSelectElement) {     
          
        while (audioInputSelectElement.firstChild) {
          audioInputSelectElement.removeChild(audioInputSelectElement.firstChild)
        }

        previewAudioInputDevicesState.forEach((device) => {
          const optionElement = document.createElement('option')
          optionElement.textContent = device.label
          audioInputSelectElement.appendChild(optionElement)
          if (device.label === selectedLabel) {
            optionElement.selected = true
          }
        })
      }
    } else {
      const optionElement = document.createElement('option')
      optionElement.textContent = "Audio input not found"
      audioInputSelectElement.appendChild(optionElement)
    }
  }

  const updateAudioOutputSelectElement = () => {
    const audioOutputSelectElement = document.getElementById('speaker-select')

    if (previewAudioOutputDevicesState.length > 0) {
      if (audioOutputSelectElement) {       
        while (audioOutputSelectElement.firstChild) {
          audioOutputSelectElement.removeChild(audioOutputSelectElement.firstChild)
        }
        previewAudioOutputDevicesState.forEach((device) => {
          const optionElement = document.createElement('option')
          optionElement.textContent = device.label
          audioOutputSelectElement.appendChild(optionElement)
        })
      } 
    } else {
        const optionElement = document.createElement('option')
        optionElement.textContent = "Audio input not found"
        audioOutputSelectElement.appendChild(optionElement)
    }
  }

  const sendChatMessage = () => {
    if (document.getElementById('chat-box-text-input').value) {
      roomChatClient.sendToAll(document.getElementById('chat-box-text-input').value)
      document.getElementById('chat-box-text-input').value = null
    } else {
      return
    }
  }
  
  const hostMuteUser = (userId) => {
    stream?.muteAudio(userId)
    console.log('muting user', userId)
  }

  const hostRemoveUser = (userId) => {
      client?.removeUser(userId)
      console.log('removing user', userId)
  }                    


  const joinCall = async () => {

    const sessionRef = doc(collection(firestore, 'sessions'), event_id)

    const sessionDoc = await getDoc(sessionRef)
    const sessionData = sessionDoc.data()
    
    setPreviewPageEnabled(false)

    setPreviewVideoDevicesState(previewVideoDevicesState)
      if(previewVideoTrackState?.isVideoStarted) {
        previewVideoTrackState?.stop()
        .catch((error) => {
          console.error('Error stopping video track:', error)
        })
    }

    if(previewAudioInputTrackState?.isAudioStarted) {
      previewAudioInputTrackState?.stop()
      .catch((error) => {
        console.error('Error stopping audio track:', error)
      })
    }

    const userJWT = {
      topic: event_id,
      user_identity: userData.firstName + ' ' + userData.lastName,
      sessionKey: event_id,
      roleType: currentUser.uid === userData.calendar[event_id]?.host && userData.calendar['session_type'] !== 'networking' ? 1 : 0
    }

    let response = await fetch('https://zoom-access-token-server-6jclwsdvxa-uk.a.run.app/generate', {
    // let response = await fetch('http://localhost:4000/generate', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userJWT)
    })

    let data = await response.json()

    if(ZoomVideo.checkSystemRequirements().video && ZoomVideo.checkSystemRequirements().audio) {
      client.init('en-US', 'Global', { patchJsMedia: true }).then(() => {
        client.join(event_id, data, currentUser.uid).then(() => {

          const allUsers = client.getAllUser()
          
          const currentUserExists = allUsers.filter(user => user.displayName === currentUser.uid && !user.isInFailover).length > 1
          
          if (currentUserExists) {
            client.leave()
            alert('User is already in the session')
            console.log('Leaving the session...')
            window.location.reload();
            return
          }

          updateVideoSelectElement()
          updateAudioInputSelectElement()
          updateAudioOutputSelectElement()
          
          setStream(client.getMediaStream())
          setRoomChatClient(client.getChatClient())

          if (sessionDoc.exists()) {
              sessionData.participants.push(currentUser.uid)
              updateDoc(sessionRef, {participants: arrayUnion(currentUser.uid) })
          } else {
            const sessionData = [
              currentUser.uid,
            ]
      
            setDoc(sessionRef, { participants: sessionData })
          }
          // Starting audio
          const isSafari = window.safari !== undefined
          if(isSafari) {
            // desktop Safari, check if desktop Safari has initialized audio
            if(audioEncode && audioDecode){
              // desktop Safari has initialized audio, continue to start audio
              client.getMediaStream().startAudio({microphoneId: previewAudioInputTrackState}).then(() => {
                if (previewMicrophoneEnabledState) {
                  client.getMediaStream().unmuteAudio().then(() => {
                  }).catch((error) => {
                    console.log(error)
                  })    
                
                } else if (!previewMicrophoneEnabledState) {
                  client.getMediaStream().muteAudio().then(() => {
                    }).catch((error) => {
                      console.log(error)
                    })    
                }
              })

            } else {
              // desktop Safari has not initialized audio, retry or handle error
              console.log('Safari audio has not finished initializing')
            }
        
          } else {
            // not desktop Safari, continue to start audio
            client.getMediaStream().startAudio({microphoneId: previewAudioInputTrackState?.deviceId}).then(() => {
              console.log('starting audio not safari')
              // setAudioStarted(true)
              if (previewMicrophoneEnabledState) {
                console.log('initializing audio, unmuting: ' + previewAudioInputTrackState?.deviceId)
                client.getMediaStream().unmuteAudio().then(() => {
                }).catch((error) => {
                  console.log(error)
                })    
              
              } else {
                console.log('initializing audio, muting: ' + previewAudioInputTrackState?.deviceId)
                client.getMediaStream().muteAudio().then(() => {
                  }).catch((error) => {
                    console.log(error)
                  })    
              }
              })
          }
          
          // creating video element + user label for self view
          if (client.getMediaStream()?.isRenderSelfViewWithVideoElement()) {
            const userVideoElement = document.getElementById('video-' + currentUser.uid)
            const userNameElement = document.getElementById('user-name-' + currentUser.uid)
            // const userMicrophoneElement = document.getElementById('microphone' + currentUser.uid)
      
            if (!userVideoElement) {
              const newUserVideoElement = document.createElement('video')
              newUserVideoElement.id = 'video-' + currentUser.uid
              newUserVideoElement.className = 'bg-white p-10'
              newUserVideoElement.width = 1920
              newUserVideoElement.height = 1080
              document.getElementById('self-video').appendChild(newUserVideoElement)
            }
      
            if (!userNameElement) {
              const newUserNameElement = document.createElement('p')
              newUserNameElement.id = 'user-name-' + currentUser.uid
              newUserNameElement.className = 'text-white'
              newUserNameElement.innerText = userData.firstName + ' ' + userData.lastName
              document.getElementById('self-video').appendChild(newUserNameElement)
            }

          } else if (!client.getMediaStream()?.isRenderSelfViewWithVideoElement()) {
      
            const userCanvasElement = document.getElementById('video-' + currentUser.uid)
            const userNameElement = document.getElementById('user-name-' + currentUser.uid)
            // const userMicrophoneElement = document.getElementById('microphone' + currentUser.uid)
      
            if (!userCanvasElement) {
              const newCanvasElement = document.createElement('video')
              newCanvasElement.id = 'video-' + currentUser.uid
              newCanvasElement.className = 'bg-white p-10'
              newCanvasElement.width = 1920
              newCanvasElement.height = 1080
              document.getElementById('self-video').appendChild(newCanvasElement)
            }
      
            if (!userNameElement) {
              const newUserNameElement = document.createElement('p')
              newUserNameElement.id = 'user-name-' + currentUser.uid
              newUserNameElement.className = 'text-white'
              newUserNameElement.innerText = userData.firstName + ' ' + userData.lastName
              document.getElementById('self-video').appendChild(newUserNameElement)
            }

          }

          if (previewVideoEnabledState) {
            client.getMediaStream().startVideo({ cameraId: previewVideoTrackState.deviceId, videoElement: document.getElementById('video-' + currentUser.uid) })
            .then(() => {
              // video successfully started and rendered
              console.log('Room Video started')
            })
            .catch((error) => {
              console.log(error)
              streamRef.current.stopVideo().then(() => {
                console.log('Video stopped')
                // setRoomVideoEnabled(false)
              }).catch((error) => {
                console.log(error)
              })
            })  
          }

          client.getAllUser().forEach((user) => {
            if (user.displayName !== currentUser?.uid && !user.isInFailover) {
              if (!document.getElementById('video-' + user.displayName)) {
                const canvas = document.createElement('canvas')
                canvas.id = 'video-' + user.displayName
                canvas.className = 'bg-white p-10'
                canvas.width = 1920
                canvas.height = 1080
                document.getElementById('participant-videos').appendChild(canvas)

                const participantName = document.createElement('p')
                participantName.id = 'user-name-' + user.displayName
                participantName.className = 'text-white'
                participantName.innerText = user.userIdentity
                document.getElementById('participant-videos').appendChild(participantName)

                const microphoneElement = document.createElement('img')
                microphoneElement.id = 'microphone-' + user.displayName
                microphoneElement.src = user?.muted ? microphoneDisabledWhiteSVG : microphoneEnabledWhiteSVG
                microphoneElement.className = 'w-12 color white rounded-full'
                document.getElementById('participant-videos').appendChild(microphoneElement)
                
              }
            }

            if(user.bVideoOn) {
              client.getMediaStream().renderVideo(document.getElementById('video-' + user.displayName), user.userId, 1920, 1080, 0, 0, 3)
            }

            if (user.sharerOn) {
              client.getMediaStream().startShareView(
                document.getElementById('participants-screen-share-content-canvas'),
                user.userId
              )
            }


          })

          client.on('user-added', payload => {

            client.getAllUser().forEach(user => {
              if (payload[0].displayName !== currentUser.uid) {
                // console.log("user added user.displayName", user.displayName)
                // console.log("user added currentUser.uid", currentUser.uid)
                // console.log("user added payload[0].displayName", payload[0].displayName)
                // console.log("user added currentUser.uid", currentUser.uid)
                  
                if (!document.getElementById('video-' + user.displayName)) {
                  const canvas = document.createElement('canvas')
                  canvas.id = 'video-' + user.displayName
                  canvas.className = 'bg-white p-10'
                  canvas.width = 1920
                  canvas.height = 1080
                  document.getElementById('participant-videos').appendChild(canvas)  

                  const participantName = document.createElement('p')
                  participantName.id = 'user-name-' + user.displayName
                  participantName.className = 'text-white'
                  participantName.innerText = payload[0].userIdentity
                  document.getElementById('participant-videos').appendChild(participantName)

                  const microphoneElement = document.createElement('img')
                  microphoneElement.id = 'microphone-' + user.displayName
                  microphoneElement.src = microphoneEnabledWhiteSVG
                  microphoneElement.className = 'w-12 color white rounded-full'
                  document.getElementById('participant-videos').appendChild(microphoneElement)
                  
                }


              }
            })

          })

          client.on('user-removed', (payload) => {
            console.log('user removed', payload)

            if (payload && payload.length > 0) {
              console.log(Object.keys(payload), 'user removed')
              }
          })
          
          client.on('user-left', (payload) => {
            console.log('user disconnected', payload)
          })

          client.on('user-updated', (payload) => {
            console.log(payload[0].userId + ' properties were updated')
            console.log(payload[0])
            console.log(Object.keys(payload[0]))
            
              if (!client.getSessionInfo().isInMeeting) {
                navigate('/login') 
              }

            client.getAllUser().forEach((user) => {
              console.log(user)
              if (user.isInFailover && user.displayName !== currentUser?.uid) {
                console.log('user is in failover', user)
                const videoElement = document.getElementById('video-' + user.displayName)
                if (videoElement) {
                  videoElement.remove()
                }
                const nameElement = document.getElementById('user-name-' + user.displayName)
                if (nameElement) {
                  nameElement.remove()
                }
                const listNameElement = document.getElementById('list-user-' + user.displayName)
                if (listNameElement) {
                  listNameElement.remove()
                }
                const userMicrophoneElement = document.getElementById('microphone-' + user.displayName)
                if (userMicrophoneElement) {
                  userMicrophoneElement.remove()
                }

              }

              if (!user.isInFailover && user.displayName !== currentUser?.uid && !document.getElementById('list-user-' + user.displayName)) {
                const userListElement = document.createElement('li')
                userListElement.id = 'list-user-' + user.displayName
                userListElement.key = user.displayName
                userListElement.className = 'relative flex'
                document.getElementById('user-list').appendChild(userListElement)

                const userNameElement = document.createElement('p')
                userNameElement.textContent = user.userIdentity
                userListElement.appendChild(userNameElement)

                if (currentUser?.uid === userData.calendar[event_id]?.host && userData.calendar[event_id].session_type !== 'networking') {
                  const optionsButton = document.createElement('button')
                  optionsButton.textContent = 'Options'
                  optionsButton.addEventListener('click', (event) => {
                    event.stopPropagation()
                    setDropdownVisible(!dropdownVisible)
                  })
                  userListElement.appendChild(optionsButton)
                }

                if (dropdownVisible) {
                  const dropdownDiv = document.createElement('div')
                  dropdownDiv.className = 'absolute bg-white text-black py-2 mt-2 rounded shadow-md'
                  dropdownDiv.addEventListener('click', (event) => {
                    event.stopPropagation()
                  })
                  userListElement.appendChild(dropdownDiv)

                  const muteUserOption = document.createElement('p')
                  muteUserOption.textContent = 'Mute User'
                  muteUserOption.className = 'px-4 py-2 hover:bg-gray-200 cursor-pointer'
                  muteUserOption.addEventListener('click', () => {
                    hostMuteUser(user.userId)
                  })
                  dropdownDiv.appendChild(muteUserOption)

                  const removeUserOption = document.createElement('p')
                  removeUserOption.textContent = 'Remove from Call'
                  removeUserOption.className = 'px-4 py-2 hover:bg-gray-200 cursor-pointer'
                  removeUserOption.addEventListener('click', () => {
                    hostRemoveUser(user.userId)
                  })
                  dropdownDiv.appendChild(removeUserOption)
                }
              }
              
              if (payload[0].userId === user.userId && user.displayName === currentUser?.uid && payload[0]?.muted) {
                setPreviewMicrophoneEnabledState(false)
              }

              const userMicrophoneElement = document.getElementById('microphone-' + user.displayName)

              if(payload[0].userId === user.userId && user.displayName !== currentUser?.uid && payload[0]?.muted && userMicrophoneElement) {
                console.log('user is muted and setting mic element to be muted. ', user.displayName)
                const userMicrophoneElement = document.getElementById('microphone-' + user.displayName)
                userMicrophoneElement.src = microphoneDisabledWhiteSVG
              } else if (payload[0].userId === user.userId && user.displayName !== currentUser?.uid && !payload[0]?.muted && userMicrophoneElement) {
                userMicrophoneElement.src = microphoneEnabledWhiteSVG
              }

            })
          })
      
          client.on('peer-video-state-change', (payload) => {
            console.log('peer-video-state-change', payload)

              if (payload.action === 'Start') {
                client.getAllUser().forEach((user) => {
                  if (payload.userId === user.userId) {
                    const peerVideo = document.getElementById('video-' + user.displayName)
                    if (peerVideo) {
                      client.getMediaStream().renderVideo(peerVideo, payload.userId, 1920, 1080, 0, 0, 3)
                    }
                  }
                })
        
              } else if (payload.action === 'Stop') {
        
                client.getAllUser().forEach((user) => {
                  if (payload.userId === user.userId) {
                    client.getMediaStream().stopRenderVideo(document.getElementById('video-' + user.displayName), payload.userId)
                  }
                })
          }
          })

          client.on('chat-on-message', (payload) => {
            // console.log(payload)
            // console.log(`Message: ${payload.message}, from ${payload.sender.name} to ${payload.receiver.name}`)
            const chatMessage = document.createElement("p")
            chatMessage.style.backgroundColor = "red"
            chatMessage.textContent = `${payload.sender.name}: ${payload.message}`
            document.getElementById('chat-messages').append(chatMessage)
          })

          client.on('active-share-change', (payload) => {
            if (payload.state === 'Active') {
              client.getMediaStream().startShareView(
                document.getElementById('participants-screen-share-content-canvas'),
                payload.userId
              )
            } else if (payload.state === 'Inactive') {
              client.getMediaStream().stopShareView()
            }
          })

          // client.on('share-audio-change', (payload) => {
          //   console.log('share-audio-change', payload)
          // })

        //   client.on('current-audio-change', (payload) => {
        //     console.log('current-audio-change', payload)
        //     if(payload.action==='join'){
        //      console.log('Joined by ',payload.type)
        //     }
        //  })

          client.on('active-speaker', (payload) => {
            console.log('Active speaker, use for CSS visuals', payload)
            const elements = document.getElementById(payload.userId)
            console.log(elements)
         })

        }).catch((error) => {
          console.error('Error joining call:', error)
        })
      }).catch((error) => {
        console.error('Error initializing client:', error)
      })
    }

  }
  
  return (
    <div className='bg-black text-white'>
    { previewPageEnabled ? (
      <>
    {previewVideoDevicesState.map(device => (
      <div className='text-white' key={device.deviceId}>
        <p>{device.label} : {device.deviceId}</p>
      </div>
    ))}

    {previewAudioInputDevicesState.map(device => (
      <div className='text-white' key={device.deviceId}>
        <p>{device.label} : {device.deviceId}</p>
      </div>
    ))}

      {previewAudioOutputDevicesState.map(device => (
        <div className='text-white' key={device.deviceId}>
          <p>{device.label} : {device.deviceId}</p>
        </div>
      ))}

      <h1 className="text-2xl text-white">Selected video: {previewVideoTrackState?.deviceId || 'Not selected'}</h1>
      <h1 className="text-2xl text-white">Selected mic: {previewAudioInputTrackState?.deviceId || 'Not selected'}</h1>
      <h1 className="text-2xl text-white">Selected speaker: {previewAudioOutputTrackState?.deviceId || 'Not selected'}</h1>
      <h1 className="text-2xl text-white">Mic on: {previewMicrophoneEnabledState ? 'on' : 'off'}</h1>
      <h1 className="text-2xl text-white">Video on: {previewVideoEnabledState ? 'on' : 'off'}</h1>
      <h1 className="text-2xl text-white">previewVideoDevicesState.length: {previewVideoDevicesState.length}</h1>
      <h1 className="text-2xl text-white">previewAudioInputDevicesState.length: {previewAudioInputDevicesState.length}</h1>
      <h1 className="text-2xl text-white">currentUser.uid: {currentUser?.uid}</h1>

      <button
          id="mic-toggle-btn"
          className={`w-12 color white rounded-full border border-white ${previewAudioInputDevicesState.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={togglePreviewAudioButton}
          disabled={previewAudioInputDevicesState.length === 0}
      >
        <img 
          src={previewMicrophoneEnabledState ? microphoneEnabledWhiteSVG : microphoneDisabledWhiteSVG}
          alt={previewMicrophoneEnabledState ? "microphone-enabled" : "microphone-disabled"}
        />
      </button>

      <button
        id="video-toggle-btn"
        className={`w-12 color white rounded-full border border-white ${previewVideoDevicesState.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        onClick={togglePreviewVideoButton}
        disabled={previewVideoDevicesState.length === 0}
      >
        <img 
          src={previewVideoEnabledState ? videoEnabledWhiteSVG : videoDisabledWhiteSVG} 
          alt={previewVideoEnabledState ? "video-enabled" : "video-disabled"} 
        />
      </button>

      <select id='video-select' className="rounded-full text-white p-4 bg-blue-500"
      onChange={(event) => switchVideo(event.target.value)}
      />
      <select id='microphone-select' className="rounded-full text-white p-4 bg-blue-500"
      onChange={(event) => switchMicrophone(event.target.value)}
      />
      <select id='speaker-select' className="rounded-full text-white p-4 bg-blue-500"
      onChange={(event) => switchSpeaker(event.target.value)}
      />
      
      <br/>
      <label htmlFor="mic-input-level">Input level:</label>
      {/* <progress id="mic-input-level" max="100" value={volumeLevel}></progress> */}
      <progress id="mic-input-level" max="100" value={previewAudioInputTrackState?.getCurrentVolume() * 1000}></progress>
      <br/>
      <label htmlFor="preview-speaker-output-level">Output level:</label>
      <progress id="preview-speaker-output-level" max="100" value="0"></progress>
      <button onClick={testSpeaker}>Test Speaker</button>
      <video id="preview-video" className="rounded-xl opacity-25" />

      <button 
          onClick={joinCall} 
          className={`rounded-full text-white p-4 bg-blue-500 ${!loadedState ? 'cursor-not-allowed' : ''}`} disabled={!loadedState}>
          Join now
        </button>
        </>
    ) : (
      <>
        <div id="self-video"></div>
        <div id='participant-videos'></div>

        <video id="my-screen-share-content-video" height="1080" width="1920"></video>
        <canvas id="my-screen-share-content-canvas" height="1080" width="1920"></canvas>
        <canvas id="participants-screen-share-content-canvas" height="1080" width="1920"></canvas>
          
        <div id='chat-box' className='bg-white'>
            <div id='chat-messages' className='bg-red'/>
        </div>

        <input id="chat-box-text-input" />
        <button className='bg-red' onClick={sendChatMessage}>Send Message</button>

        <button
          id="mic-toggle-btn"
          className={`w-12 color white rounded-full border border-white ${previewAudioInputDevicesState.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
          onClick={togglePreviewAudioButton}
          disabled={previewAudioInputDevicesState.length === 0}
        >
        <img 
          src={previewMicrophoneEnabledState ? microphoneEnabledWhiteSVG : microphoneDisabledWhiteSVG}
          alt={previewMicrophoneEnabledState ? "microphone-enabled" : "microphone-disabled"}
        />
      </button>

      <button
        id="video-toggle-btn"
        className={`w-12 color white rounded-full border border-white ${previewVideoDevicesState.length === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        onClick={togglePreviewVideoButton}
        disabled={previewVideoDevicesState.length === 0}
      >
        <img 
          src={previewVideoEnabledState ? videoEnabledWhiteSVG : videoDisabledWhiteSVG} 
          alt={previewVideoEnabledState ? "video-enabled" : "video-disabled"} 
        />
      </button>

      <button
        id="screen-share-toggle-btn"
        className="w-12 color white rounded-full border border-white"
        onClick={toggleScreenShareButton}
        disabled={previewVideoDevicesState.length === 0}
      >
        <img 
          src={roomScreenSharedEnabledState ? screenShareWhiteSVG : screenShareWhiteSVG} 
          alt={roomScreenSharedEnabledState ? "share-enabled" : "share-disabled"} 
        />
      </button>

      <select id='video-select' className="rounded-full text-white p-4 bg-blue-500"
      onChange={(event) => switchVideo(event.target.value)}
      />
      <select id='microphone-select' className="rounded-full text-white p-4 bg-blue-500"
      onChange={(event) => switchMicrophone(event.target.value)}
      />
      <select id='speaker-select' className="rounded-full text-white p-4 bg-blue-500"
      onChange={(event) => switchSpeaker(event.target.value)}
      />

      <div  className='bg-blue-200'>
        <h1>Users</h1>
        <ul id='user-list'>
        {client.getAllUser().filter(user => !user.isInFailover && user.displayName !== currentUser?.uid).map((user) => {
        
        return (
          
          <li id={"list-user-" + user.displayName} key={user.displayName} className="relative flex">
            
            <p>{user.userIdentity}</p>

            {console.log('currentUser.uid', currentUser?.uid)}
            {console.log('userData.calendar[event_id]?.host', userData.calendar[event_id]?.host)}
            {console.log('userData.calendar[event_id]', userData.calendar[event_id])}

            {(currentUser?.uid === userData.calendar[event_id]?.host && userData?.calendar[event_id].session_type !== 'networking') && (
                <button
                onClick={(event) => {
                  event.stopPropagation()
                  setDropdownVisible(!dropdownVisible)
                }}
              >
                Options
              </button>
            )}

            {dropdownVisible && (
              <div ref={dropdownRef} className="absolute bg-white text-black py-2 mt-2 rounded shadow-md">
                <p onClick={() => hostMuteUser(user.userId)} className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Mute User</p>
                <p onClick={() => hostRemoveUser(user.userId)} className="px-4 py-2 hover:bg-gray-200 cursor-pointer">Remove from Call</p>
              </div>
              )}
          </li>
        )
        })}

          </ul>

        </div>

      </>
    )}
    </div>
  )
}

export default VideoRefined