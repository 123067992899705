import React from 'react';
import purple from '../assets/purple.png';

function ImageTextCardTwo() {
  return (
    <div className="flex items-center justify-center py-20 container mx-auto bg-red-500">
      <div className="flex shadow-md">
        <div className="bg-red-100 w-3/4 rounded-lg mr-4 p-4">
          <h2 className="text-lg font-medium text-gray-800">Title</h2>
          <p className="text-gray-600 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
        <div className="bg-blue-200 w-1/4 rounded-lg">
          <img
            src={purple}
            alt="textcardtwo"
            className="rounded-lg"
          />
        </div>
      </div>
    </div>
  );
}

export default ImageTextCardTwo;
