import React, { useState } from 'react';
import logo from '../assets/logo.png';
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');

  const handleFormSubmit = (e) => {
    e.preventDefault();
    navigate(`/search?query=${searchQuery}`);
  }
  
  return (    
    <header className="sticky z-10 w-full py-3 bg-red-500">
      <div className="container flex items-center justify-between mx-auto bg-orange-400 px-20">
        <div className="flex items-center bg-purple-200">
          <img src={logo} alt="Logo" className="h-10" />
          <div className="bg-blue-300 text-white text-xl font-bold">My Website</div>
        </div>
        <div className="flex items-center">
          <nav>


              <header className="sticky z-10 w-full py-3 bg-red-500">
                <div className="container flex items-center justify-between mx-auto bg-orange-400 px-20">
                  <div className="flex items-center bg-purple-200">
                  </div>
                  <div className="flex items-center">
                    <nav>
                      <form onSubmit={handleFormSubmit}>
                        <input
                          type="text"
                          placeholder="Search"
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                          className="ml-4 px-2 py-1 rounded-md"
                        />
                        <button type="submit" className="ml-2 px-4 py-1 bg-blue-500 text-white rounded-md">
                          Go
                        </button>
                      </form>
                    </nav>
                  </div>
                </div>
              </header>

            <ul className="flex">
              <li className="">
                <a href="/login" className="font-semibold text-white hover:text-gray-200">Login</a>
              </li>
              <li className="ml-4">
                <a href="/signup" className="font-semibold text-white hover:text-gray-200">Sign Up</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;
