import React, { useState, useContext } from 'react'
import Header from './Header';
import Footer from './Footer';
import Calendar from './Calendar';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import purple from '../assets/purple.png';

function Profile() {
  
  const { profileURL } = useParams();

  const [isCalOpen, setCalOpen] = useState(false);
  
  // eslint-disable-next-line
  const { currentUser, userData, loadedState } = useContext(AuthContext);

  const openCalendar = () => {
    setCalOpen(true);
  };

  const closeCalendar = () => {
    setCalOpen(false);
  };

  const handleOverlayClick = (event) => {
    if (event.target.classList.contains('overlay')) {
      closeCalendar();
    }
  };
  
  return (
    <div>
        <Header/>
        
        <div className='container flex flex-row mx-auto my-10 space-x-10 px-16'>
          
          <div className='basis-3/4 space-y-4'>
          
            <div className="relative">
              <img className="w-full rounded rounded-tl-lg rounded-tr-lg h-72"src={purple} alt="background"/>

              <div className="flex-col justify-center items-center absolute left-1/2 transform -translate-x-1/2 -translate-y-48">
                <img className="rounded-xl shadow-lg" src={purple} alt="profile" />
                <p className="text-center">{userData && userData.Fname}</p>
                <p className="text-center">{userData && userData.Lname}</p>
                <p className="text-center">Description</p>
              </div>

              <div className="flex justify-end bg-green-100 rounded-bl-lg rounded-br-lg h-72">
                <div className=''>
                  {currentUser && (
                  <button onClick={openCalendar} className='bg-yellow-300 p-5 absolute bottom-0 right-0'>calendar</button>
                   )}
                   {isCalOpen && (
                    <div  onClick={handleOverlayClick} className="overlay fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-20">
                      <div className="bg-white p-8 rounded-lg">
                        <Calendar profileURL={profileURL} />
                      </div>
                    </div>
                  )}
                </div>
                
              </div>
            </div>

            <div className='bg-blue-100 rounded-lg p-5'> 
              <p>About</p>
              <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius eleifend nisi, nec consectetur purus bibendum id. Cras id aliquam velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam non volutpat dolor. Integer mollis elit non ex efficitur, vitae tristique eros rutrum. Donec ultrices iaculis massa, ut venenatis massa malesuada vel. Ut id nunc ut neque sagittis scelerisque.
              </p>

            </div>
            <div className='bg-blue-100 rounded-lg p-5'> 
              <p>Licenses & certifications</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius eleifend nisi, nec consectetur purus bibendum id. Cras id aliquam velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam non volutpat dolor. Integer mollis elit non ex efficitur, vitae tristique eros rutrum. Donec ultrices iaculis massa, ut venenatis massa malesuada vel. Ut id nunc ut neque sagittis scelerisque.
              </p>
            </div>
            
              <div className='bg-blue-100 rounded-lg p-5'> 
                <p>Recommendations</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius eleifend nisi, nec consectetur purus bibendum id. Cras id aliquam velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam non volutpat dolor. Integer mollis elit non ex efficitur, vitae tristique eros rutrum. Donec ultrices iaculis massa, ut venenatis massa malesuada vel. Ut id nunc ut neque sagittis scelerisque.
                </p>
              </div>
            
          
            <div className='bg-blue-100 rounded-lg p-5'> 
                <p>Publications</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius eleifend nisi, nec consectetur purus bibendum id. Cras id aliquam velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam non volutpat dolor. Integer mollis elit non ex efficitur, vitae tristique eros rutrum. Donec ultrices iaculis massa, ut venenatis massa malesuada vel. Ut id nunc ut neque sagittis scelerisque.
                </p>
            </div>     

            <div className='bg-blue-100 rounded-lg p-5'> 
                <p>Languages</p>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed varius eleifend nisi, nec consectetur purus bibendum id. Cras id aliquam velit. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Nullam non volutpat dolor. Integer mollis elit non ex efficitur, vitae tristique eros rutrum. Donec ultrices iaculis massa, ut venenatis massa malesuada vel. Ut id nunc ut neque sagittis scelerisque.
                </p>
            </div>
            
          </div>
          
          {/* add button for calendar on their first card, stars & acheivements, message, friend, etc. */}
        
          <div className='bg-red-100 basis-1/4 space-y-4'>
            <div className='p-10 bg-green-200 rounded-lg'>
                text
              </div>

            <div className='p-10 bg-green-200 rounded-lg'>
              text
            </div>  

            <div className='p-10 bg-green-200 rounded-lg'>
              text
            </div>  
          </div>
          </div>
        <Footer/>
    </div>    
  );
};

export default Profile