import './index.css';
import React from 'react';
import Home from './components/Home';
import SignUp from './components/SignUp';
import Profile from './components/Profile';
import Login from './components/Login';
import VideoCall from './components/VideoCall';
import VideoRefined from './components/VideoRefined';
import Search from './components/Search';
import Stripe from './Stripe';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './AuthContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
const stripePromise = loadStripe('pk_test_51ILtpZKeBulusOWdw43KMXEXdFH6FiykuxQDS0PPptsbT0H2hQuHt1GtGGJ31TRgeSTSu2u69zrwxNii8lC99XVL00gvqSMZFC');

const options = {
  // passing the client secret obtained from the server
  clientSecret: 'sk_test_51ILtpZKeBulusOWdtFFOwXYcQOLt3ERPnCvRwUnRdlH864UynyvzEEyoD0HbsuwOc73ZfW76QJxWMHWUcOPnR4Ro00rDr2ItqK'
};  

root.render(
  // <React.StrictMode> 
    <AuthProvider>
      <BrowserRouter>
        <Routes>
            <Route path="/" element={<Home />}/>
            
            <Route path="/signup" element={<SignUp />}/>

            <Route path="/home" element={<Home />}/>

            <Route path="/profile/:profileURL" element={<Profile />}/>
  
            <Route path="/login" element={<Login />}/>

            <Route path="/video/:event_id" element={<VideoCall />}/>
            <Route path="/video2/:event_id" element={<VideoRefined />}/>

            <Route path="/search" element={<Search />} />

            <Route path="/stripe" element={
            <Elements stripe={stripePromise} options={options}>
              <Stripe />
            </Elements>
            }/>

        </Routes>
      </BrowserRouter>
    </AuthProvider>
  // </React.StrictMode>
);  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
